import React from 'react';

import { state, sequences } from 'cerebral';
import { connect } from '@cerebral/react';

import { BrowserRouter as Router, Switch } from "react-router-dom";

import { authRequiredRoutes, defaultRoutes } from "./routes";

import NotificationStack from "./components/molecules/notification-stack";
import Error404 from "./components/organisms/error-404";
import Loader from "./components/atoms/loader";

import './app.scss';

class App extends React.Component {

  componentDidMount() {
    this.props.setup();
  }

  render() {

    if (this.props.isInitializing) {
      return (
        <div className="app-loader">
          <Loader size="large" />
        </div>
      );
    }

    let error;

    if (this.props.showError404) {
      error = <Error404 />;
    }

    let notLoggedIn;
    let loggedIn;

    if (this.props.isAuthenticated) {
      loggedIn = authRequiredRoutes;
    } else {
      notLoggedIn = defaultRoutes;
    }

    return (
      <div className="app">
        <Router basename="">
          <Switch>
            {error}
            {loggedIn}
            {notLoggedIn}
          </Switch>
        </Router>
        <NotificationStack />
      </div>
    );
  }
}

export default connect({
  setup: sequences.setup,
  showError404: state.utils.showError404,
  isInitializing: state.utils.isInitializing,
  isAuthenticated: state.users.isAuthenticated
}, App);