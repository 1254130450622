import React from 'react';

import { connect } from '@cerebral/react';
import { state } from 'cerebral';

import './index.scss';

class SidebarUser extends React.Component {

  static defaultProps = {
    user: {}
  };

  render() {

    return (
      <div className="sidebar-user">
        <div className="sidebar-user__name">{this.props.user.firstName} {this.props.user.lastName}</div>
        <div className="sidebar-user__store-name">{this.props.user.storeName}</div>
        <div className="sidebar-user__email">{this.props.user.email}</div>
      </div>
    );
  }
}

export default connect({
  user: state.users.user
}, SidebarUser);