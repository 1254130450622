import React from "react";

import Slideshow from "../slideshow";
import LoadingImage from "../../atoms/loading-image";

import "./index.scss";

export default class ImageSlideshow extends React.Component {
  static defaultProps = {
    images: [],
  };

  constructor(props) {
    super(props);

    this.slideshow = React.createRef();
  }

  goTo(index) {
    this.slideshow.current.goTo(index);
    // this.refs['pagination'].goTo(index, false);
  }

  // onSlideshowGoTo(index) {
  //   this.refs['pagination'].goTo(index, false);
  // }

  // onPaginationGoTo(index) {
  //   this.refs['slideshow'].pause();
  //   this.refs['slideshow'].goTo(index, false);
  // }

  render() {
    return (
      <div className="image-slideshow">
        <Slideshow autoplay={false} ref={this.slideshow}>
          {this.props.images.map((image) => {
            return <LoadingImage key={image.id} src={image.medium} />;
          })}
        </Slideshow>
        <div className="image-slideshow__thumbs">
          {this.props.images.map((image, i) => {
            return (
              <div
                key={image.id}
                className="image-slideshow__thumb"
                onClick={(e) => this.goTo(i)}
              >
                <LoadingImage src={image.thumb} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

