import React from 'react';

import './index.scss';

export default class SidebarContent extends React.Component {

  render() {
    return (
      <div className="sidebar-content">
        {this.props.children}
      </div>
    );
  }
};