import React from 'react';

import { state, sequences } from 'cerebral';
import { connect } from '@cerebral/react';
import { withRouter } from 'react-router-dom';

import { data, label, countries } from "../../../util/data";

import { validateForm } from "../../../util/validation";

import LatestOrderWidget from "../../../components/molecules/latest-order-widget";
import DataValue from "../data-value";
import Button from "../../atoms/button";
import Widget from "../widget";
import SlideOutPanel from '../slide-out-panel';
import ContentPane from "../content-pane";
import TextInput from "../../atoms/text-input";
import Form from "../form";
import InputStack from "../input-stack";
import SelectInput from "../../atoms/select-input";

import './index.scss';

class UserView extends React.Component {

  static defaultProps = {
    user: {},
    showEditUserPanel: false,
    showEditUserDeliveryPanel: false,
    showEditUserInvoicePanel: false,
    notify: () => {}
  };

  constructor(props) {

    super(props);

    this.userForm = React.createRef();
    this.userInvoiceForm = React.createRef();
    this.userDeliveryForm = React.createRef();

    this.validateUser = this.validateUser.bind(this);
    this.validateInvoiceData = this.validateInvoiceData.bind(this);
    this.validateDeliveryData = this.validateDeliveryData.bind(this);
  }

  updateUser(data) {
    this.props.updateUser(data);
  }

  validateUser(form, data) {

    validateForm(form, data, {
      firstName: ['required'],
      lastName: ['required'],
      email: ['required', 'email'],
      telephone: ['required'],
      storeName: ['required']
    });
  }

  validateInvoiceData(form, data) {

    validateForm(form, data, {
      companyName: ['required'],
      addressStreet: ['required'],
      addressStreetNumber: ['required'],
      addressPostalCode: ['required'],
      addressCity: ['required'],
      addressCountry: ['required']
    });
  }

  validateDeliveryData(form, data) {

    validateForm(form, data, {
      deliveryFirstName: ['required'],
      deliveryLastName: ['required'],
      deliveryAddressStreet: ['required'],
      deliveryAddressStreetNumber: ['required'],
      deliveryAddressPostalCode: ['required'],
      deliveryAddressCity: ['required'],
      deliveryAddressCountry: ['required']
    });
  }

  render() {

    return (
      <div className="user-view">
        <ContentPane title="My account">

          <div className="user-view__content">
            <div className="user-view__col">
              <div className="user-view__row">
                <Widget title={data.myInformation} header={<Button icon="edit" look="small" text={data.editInformation} onClick={e => this.props.history.push('/user/edit/')}/>}>
                  <DataValue title={label.name}>{this.props.user.firstName+' ' +this.props.user.lastName}</DataValue>
                  <DataValue title={label.email}>{this.props.user.email}</DataValue>
                  <DataValue title={label.telephone}>{this.props.user.telephone}</DataValue>
                  <DataValue title={label.storeName}>{this.props.user.storeName}</DataValue>
                </Widget>
              </div>
              <div className="user-view__row">
                <Widget title={data.invoiceData} header={<Button icon="edit" look="small" text={data.editInvoiceData} onClick={e => this.props.history.push('/user/edit-invoice/')}/>}>
                  <DataValue title={label.companyName}>{this.props.user.companyName}</DataValue>
                  <DataValue title={label.vat}>{this.props.user.companyVatNumber}</DataValue>
                  <DataValue title={label.address}>
                    {this.props.user.addressStreet+' '+this.props.user.addressStreetNumber}<br />
                    {this.props.user.addressPostalCode+' '+this.props.user.addressCity}<br />
                    {this.props.user.addressCountry}
                  </DataValue>
                </Widget>
              </div>
              <div className="user-view__row">
                <Widget title={data.deliveryData} header={<Button icon="edit" look="small" text={data.editDeliveryData} onClick={e => this.props.history.push('/user/edit-delivery/')}/>}>
                  <DataValue title={label.name}>{this.props.user.deliveryFirstName+' ' +this.props.user.deliveryLastName}</DataValue>
                  <DataValue title={label.address}>
                    {this.props.user.deliveryAddressStreet+' '+this.props.user.deliveryAddressStreetNumber}<br />
                    {this.props.user.deliveryAddressPostalCode+' '+this.props.user.deliveryAddressCity}<br />
                    {this.props.user.deliveryAddressCountry}
                  </DataValue>
                </Widget>
              </div>
            </div>
            <div className="user-view__col">
              <LatestOrderWidget />
            </div>
          </div>

          <SlideOutPanel title={data.myInformation} isOpen={this.props.showEditUserPanel} onClose={e => this.props.history.push('/user/')} footer={<Button text={data.saveInformation} onClick={e => {this.userForm.current && this.userForm.current.submit(e)}}/>}>
            <Form ref={this.userForm} validate={this.validateUser} onSubmit={data => this.updateUser(data)} showSubmitButton={false}>
              <InputStack>
                <TextInput name="firstName" label={label.firstName} default={this.props.user.firstName} showRequiredIndicator />
                <TextInput name="lastName" label={label.lastName} default={this.props.user.lastName} showRequiredIndicator />
              </InputStack>
              <TextInput name="email" label={label.email} default={this.props.user.email} showRequiredIndicator />
              <TextInput name="telephone" label={label.telephone} default={this.props.user.telephone} showRequiredIndicator />
              <TextInput name="storeName" label={label.storeName} default={this.props.user.storeName} showRequiredIndicator />
            </Form>
          </SlideOutPanel>

          <SlideOutPanel title={data.invoiceData} isOpen={this.props.showEditUserInvoicePanel} onClose={e => this.props.history.push('/user/')} footer={<Button text={data.saveInvoiceData} onClick={e => {this.userInvoiceForm.current && this.userInvoiceForm.current.submit(e)}}/>}>
            <Form ref={this.userInvoiceForm} validate={this.validateInvoiceData} onSubmit={data => this.updateUser(data)} showSubmitButton={false}>
              <InputStack>
                <TextInput name="companyName" label={label.companyName} default={this.props.user.companyName} showRequiredIndicator />
                <TextInput name="companyVatNumber" label={label.vat} default={this.props.user.companyVatNumber} />
              </InputStack>
              <InputStack>
                <TextInput name="addressStreet" label={label.street} default={this.props.user.addressStreet} showRequiredIndicator />
                <TextInput name="addressStreetNumber" label={label.number} default={this.props.user.addressStreetNumber} showRequiredIndicator />
              </InputStack>
              <InputStack>
                <TextInput name="addressPostalCode" label={label.postalCode} default={this.props.user.addressPostalCode} showRequiredIndicator />
                <TextInput name="addressCity" label={label.city} default={this.props.user.addressCity} showRequiredIndicator />
              </InputStack>
              <SelectInput name="addressCountry" label={label.country} default={this.props.user.addressCountry} options={countries} showRequiredIndicator />
            </Form>
          </SlideOutPanel>

          <SlideOutPanel title={data.deliveryData} isOpen={this.props.showEditUserDeliveryPanel} onClose={e => this.props.history.push('/user/')} footer={<Button text={data.saveDeliveryData} onClick={e => {this.userDeliveryForm.current && this.userDeliveryForm.current.submit(e)}}/>}>
            <Form ref={this.userDeliveryForm} validate={this.validateDeliveryData} onSubmit={data => this.updateUser(data)} showSubmitButton={false}>
              <InputStack>
                <TextInput name="deliveryFirstName" label={label.firstName} default={this.props.user.deliveryFirstName} />
                <TextInput name="deliveryLastName" label={label.lastName} default={this.props.user.deliveryLastName} />
              </InputStack>
              <InputStack>
                <TextInput name="deliveryAddressStreet" label={label.street} default={this.props.user.deliveryAddressStreet} />
                <TextInput name="deliveryAddressStreetNumber" label={label.number} default={this.props.user.deliveryAddressStreetNumber} />
              </InputStack>
              <InputStack>
                <TextInput name="deliveryAddressPostalCode" label={label.postalCode} default={this.props.user.deliveryAddressPostalCode} />
                <TextInput name="deliveryAddressCity" label={label.city} default={this.props.user.deliveryAddressCity} />
              </InputStack>
              <SelectInput name="deliveryAddressCountry" label={label.country} default={this.props.user.deliveryAddressCountry} options={countries} />
            </Form>
          </SlideOutPanel>

        </ContentPane>
      </div>
    );
  }
}

export default withRouter(connect({
  notify: sequences.utils.notify,
  user: state.users.user,
  updateUser: sequences.users.updateUser
}, UserView));