import React from 'react';

import './index.scss';

class DataValue extends React.Component {

  static defaultProps = {
    'title': 'Data value'
  };

  render() {

    return (
      <div className="data-value">
        <div className="data-value__title">{this.props.title}</div>
        <div className="data-value__content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default DataValue;