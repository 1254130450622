export const price = (value) => {
  return '€ ' + value;
};

export const date = (timestamp) => {

    let date = new Date(timestamp * 1000);

    let day = date.getDate();
    let month = date.getMonth()+1;

    return leadingZero(day)+'/'+leadingZero(month)+'/'+date.getFullYear();
};

export const leadingZero = (number) => {
  return (number > 10 ? number : '0'+number);
};