import React from 'react';

import './index.scss';

class Toolbar extends React.Component {

  render() {

    let content;

    if (this.props.children.length > 1) {

      content = this.props.children.map((child, i) => {

        return (
          <div key={i} className="toolbar__item">
            {child}
          </div>
        );
      });
    } else {
      content = (
        <div className="toolbar__item">
          {this.props.children}
        </div>
      );
    }

    return (
      <div className="toolbar">
        {content}
      </div>
    );
  }
}

export default Toolbar;