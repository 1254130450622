import React from 'react';
import Icon from '../icon';

import './index.scss';

export default class IconButton extends React.Component {

  static defaultProps = {
    'icon': null,
    'iconStyle': 'medium',
    'type': 'button',
    onClick: () => {}
  };

  render() {

    let icon = <Icon type={this.props.icon} look={this.props.iconStyle} colored />;

    if (this.props.iconStyle === 'large') {
      icon = <Icon type={this.props.icon} look={this.props.iconStyle} background />;
    }

    return (
      <button className="icon-button" type={this.props.type} onClick={this.props.onClick}>
        {icon}
      </button>
    );
  }
}
