import React from 'react';
import './index.scss';
import IconButton from "../../atoms/icon-button";
import Button from "../../atoms/button";

class ActionInput extends React.Component {

  static defaultProps = {
    field: null,
    buttonText: '',
    buttonIcon: '',
    onClick: () => {}
  };

  constructor(props) {
    super(props);

    this.field = React.createRef();
  }

  onClick() {
    let data = this.field.current.getValue();
    this.props.onClick(data);
  }

  getButton() {
    if (this.props.buttonText) {
      return <Button text={this.props.buttonText} icon={this.props.buttonIcon} onClick={e => this.onClick()} />
    }

    return <IconButton icon={this.props.buttonIcon} onClick={e => this.onClick()} />
  }

  getField() {
    const TagName = this.props.field.type;
    return <TagName ref={this.field} {...this.props.field.props} />
  }

  render() {

    let button = this.getButton();
    let field = this.getField();

    return (
      <div className="action-input">
        <div className="action-input__field">
          {field}
        </div>
        <div className="action-input__button">
          {button}
        </div>
      </div>
    );
  }
}

export default ActionInput;