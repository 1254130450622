import React from 'react';

import './index.scss';

class Table extends React.Component {

  static defaultProps = {
    cols: []
  };

  render() {

    let header;
    let colgroup;
    let headers = [];
    let cols = [];

    if (this.props.cols.length) {

      this.props.cols.forEach((col, i) => {

        // @TODO check if header is needed

        headers.push(
          <th className="table__header" key={i}>
            {col.header}
          </th>
        );

        cols.push(
          <col width={col.width} span={col.span} key={i} />
        );
      });

      header = (
        <thead>
          <tr>{headers}</tr>
        </thead>
      );

      colgroup = (
        <colgroup>{cols}</colgroup>
      );
    }

    return (
      <div className="table">
        <table cellPadding="0" cellSpacing="0">
          {colgroup}
          {header}
          <tbody>
            {this.props.children}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;