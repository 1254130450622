import React from 'react';

import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';

import Notification from "../notification";

import './index.scss';

class NotificationStack extends React.Component {

  static defaultProps = {
    notifications: [
      {
        id: 1,
        text: 'hi there'
      },
      {
        id: 2,
        text: 'hi there, again'
      }
    ],
  };

  render() {
    let notifications = this.props.notifications.map(notification => {
      return (
        <div key={notification.id} className="notification-stack__item">
          <Notification text={notification.text} icon={notification.icon} duration={notification.duration} />
        </div>
      );
    });

    return (
      <div className="notification-stack">
        {notifications}
      </div>
    );
  }
}

export default connect({
  notify: sequences.utils.notify,
  notifications: state.utils.notifications
}, NotificationStack);