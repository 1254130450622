import React from 'react';

import { withRouter } from 'react-router-dom';

import { data } from '../../../util/data';

import Header from '../header/index';
import Sidebar from '../../molecules/sidebar/index';
import SidebarContent from '../../molecules/sidebar-content/index';
import Button from '../../atoms/button/index';
import CollectionNav from '../../../components/molecules/collection-nav';
import UserNav from '../../molecules/user-nav/index';
import SidebarUser from '../../../components/molecules/sidebar-user';
import CartSlideOutPanel from '../../organisms/cart-slide-out-panel';
import SimpleCollectionNav from '../../molecules/simple-collection-nav';

class BaseLayout extends React.Component {

  static defaultProps = {
    mode: 'shop',
    simpleSidebar: false
  };

  render() {

    let sidebarContent;
    let sideBarFooter;

    if (this.props.mode === 'shop') {
      sideBarFooter = (
        <Button look="large" icon="arrow-left" text={data.myAccount} onClick={e => this.props.history.push('/user/')}/>
      );
    } else if (this.props.mode === 'user') {
      sideBarFooter = (
        <Button look="large" icon="arrow-left" text={data.shop} onClick={e => this.props.history.push('/shop/')} />
      );
    }

    if (! this.props.simpleSidebar) {

      if (this.props.mode === 'shop') {
        sidebarContent = (
          <CollectionNav />
        );
      } else if (this.props.mode === 'user') {
        sidebarContent = (
          <UserNav />
        );
      }
    } else {
      sidebarContent = (
        <SimpleCollectionNav />
      );
    }

    return (
      <div>
        <Sidebar>
          <div className="sidebar__content">
            {sidebarContent}
          </div>
          <div className="sidebar__footer">
            <SidebarUser />
            {sideBarFooter}
          </div>
        </Sidebar>
        <SidebarContent>
          <Header />
          {this.props.children}
        </SidebarContent>
        <CartSlideOutPanel />
      </div>
    );
  }
}

export default withRouter(BaseLayout);