import React from 'react';

import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { withRouter } from 'react-router-dom';

import Loader from '../../../components/atoms/loader';

import './index.scss';

class CategoryNav extends React.Component {

  static defaultProps = {
    categories: []
  };

  goToCategory(category) {
    this.props.history.push('/shop/'+this.props.ageGroup.slug+'/'+this.props.gender.slug+'/'+this.props.collection.slug+'/'+category.slug+'/');
  }

  render() {

    if (! this.props.category) {
      return <Loader />;
    }

    let categories = this.props.categories.map(category => {
      return (
        <button className={'category-nav__item' + (this.props.category.id === category.id ? ' category-nav__item--active' : '')} key={category.id} onClick={e => this.goToCategory(category)}>
          {category.name}
        </button>
      );
    });

    return (
      <div className="category-nav">
        {categories}
      </div>
    );
  }
}

export default withRouter(connect({
  ageGroup: state.shop.ageGroup,
  gender: state.shop.gender,
  categories: state.shop.categories,
  category: state.shop.category,
  collection: state.shop.collection
}, CategoryNav));