import React from 'react';

import { data } from '../../../util/data';

import ActionInput from '../action-input';
import NumberInput from '../../atoms/number-input';
import SelectInput from '../../atoms/select-input';

import './index.scss';
import LoadingImage from "../../atoms/loading-image";

class ProductItem extends React.Component {

  static defaultProps = {
    'title': '',
    'price': 0,
    'photo': '',
    'variations': [],
    onClick: () => {},
    onAddToCart: () => {}
  };

  constructor(props) {

    super(props);

    this.variationsInput = React.createRef();
  }

  getVariationSelectOptions() {

    let variations = {};

    if (this.props.variations.length) {
      this.props.variations.forEach(variation => {
        if (variation.inStock > 0) {
          variations[variation.id] = variation.size.name + ' - € ' + variation.price;
        }
      });
    }

    return variations;
  }

  getVariationItems() {
    return this.props.variations.map((variation, index) => {
      return (
        <li key={index} className={'product-item__variations-item'+(variation.inStock <= 0 ? ' product-item__variations-item--out-of-stock' : '')}>
          {variation.size.name}
        </li>
      );
    });
  }

  addToCart(amount) {
    this.props.onAddToCart(this.variationsInput.current.getValue(), amount);
  }

  render() {

    let variations = this.getVariationSelectOptions();
    let defaultVariation = Object.keys(variations)[0];

    let productFooter;

    if (defaultVariation) {
      productFooter = (
        <div className="product-item__footer">
          <div className="product-item__variation-select">
            <SelectInput windowTitle={data.selectSize} ref={this.variationsInput} name="product-item__variations" options={variations} default={defaultVariation} />
          </div>
          <div className="product-item__amount-input">
            <ActionInput field={<NumberInput/>} buttonIcon="shopping-cart" onClick={amount => this.addToCart(amount)} />
          </div>
        </div>
      );
    }

    return (
      <div className="product-item">
        <div className="product-item__header">
          <h4 className="product-item__title" onClick={this.props.onClick}>{this.props.title}</h4>
          <div className="product-item__price">€ {this.props.price}</div>
        </div>
        <div className="product-item__content">
          <ul className="product-item__variations">
            {this.getVariationItems()}
          </ul>
          <div className="product-item__img" onClick={this.props.onClick}>
            <LoadingImage src={this.props.photo.medium} />
          </div>
        </div>
        {productFooter}
      </div>
    );
  }
}

export default ProductItem;