import React from 'react';

import Loader from '../../../components/atoms/loader';
import UserWidget from '../../../components/molecules/user-widget';
import CartWidget from '../../../components/molecules/cart-widget';

import './index.scss';

class Header extends React.Component {

  render() {

    return (
      <div className="header">
        <Loader />
        <div className="header__list">
          <div className="header__list-item">
            <CartWidget/>
          </div>
          <div className="header__list-item">
            <UserWidget/>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;