import React from 'react';

import './index.scss';

export default class TextInput extends React.Component {

  static defaultProps = {
    default: '',
    label: '',
    name: '',
    disabled: false,
    showRequiredIndicator: false,
    onChange: () => {}
  };

  constructor(props) {

    super(props);

    this.state = {
      id: 'input-'+this.props.name+'-'+Math.random().toString(36).substring(7),
      value: this.props.default
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {

    if (this.props.default !== prevProps.default) {

      this.setState({
        value: this.props.default
      });
    }
  }

  getValue() {
    return this.state.value;
  }

  handleChange(e) {

    this.setState({
      value: e.target.value
    });

    this.props.onChange(this.state.value);
  }

  handleSubmit(data) {
    data[this.props.name] = this.getValue();
  }

  render() {

    let label;
    let requiredIndicator;

    if (this.props.showRequiredIndicator) {
      requiredIndicator = <span className="text-input__required-indicator">*</span>;
    }

    if (this.props.label) {
      label = (
        <label htmlFor={this.state.id} className="text-input__label">
          {this.props.label}
          {requiredIndicator}
        </label>
      );
    }

    return (
      <div className="text-input">
        {label}
        <input id={this.state.id} name={this.props.name} className="text-input__input" type="text" defaultValue={this.props.default}
               disabled={this.props.disabled}
               onChange={this.handleChange} onKeyUp={this.handleChange} />
      </div>
    );
  }
}