import React from 'react';

import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { withRouter } from 'react-router-dom';

import { data } from '../../../util/data';
import LoadingImage from '../../atoms/loading-image';
import Button from '../../atoms/button';

import './index.scss';

class AgeGroupList extends React.Component {

  static defaultProps = {
    collection: null,
    firstCollection: null
  };

  componentDidMount() {
    this.props.loadCollections();
  }

  goTo(ageGroup, type = '') {
    return () => {
      this.props.history.push(
        `/shop/${ageGroup.slug}/girls/${this.props.firstCollection.slug}/${type}`)
    }
  }

  photoSource(ageGroup) {
    if (ageGroup.slug === 'kids') {
      return this.props.firstCollection.photoGirls.large;
    }

    if (this.props.firstCollection.photoTeenGirls) {
      return this.props.firstCollection.photoTeenGirls.large;
    }

    if (this.props.firstCollection.photoTeenBoys) {
      return this.props.firstCollection.photoTeenBoys.large;
    }

    return this.props.firstCollection.photoBoys.large;
  }

  render() {

    if (!this.props.firstCollection) {
      return null;
    }

    return (
      <div className="age-group-list">
        {this.props.ageGroups.map((ageGroup, index) => (
          <div className="age-group-list__item" key={'age-group-list_'+ageGroup.slug}>
            <h2 className="age-group-list__title" onClick={this.goTo(ageGroup)}>{ageGroup.title}</h2>
            <div className="age-group-list__photo" onClick={this.goTo(ageGroup)}>
              <LoadingImage src={this.photoSource(ageGroup)}/>
            </div>
            <div className={'age-group-list__content-box' + (index % 2 ? ' age-group-list__content-box--alternate' : '')}>
              <h3 className="age-group-list__subtitle">
                {this.props.firstCollection.title}
              </h3>
              <div className="age-group-list__content-footer">
                <div className="age-group-list__footer-item">
                  <Button icon="image" text={data.lookbook} onClick={this.goTo(ageGroup, 'lookbook')}/>
                </div>
                <div className="age-group-list__footer-item">
                  <Button icon="shopping-bag" text={data.shop} onClick={this.goTo(ageGroup)}/>
                </div>
                <div className="age-group-list__footer-item">
                  <Button icon="image" text={data.silhouettes} onClick={this.goTo(ageGroup, 'silhouettes')}/>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(connect({
  ageGroups: state.shop.ageGroups,
  firstCollection: state.shop.firstCollection,
  loadCollections: sequences.shop.loadCollections
}, AgeGroupList));