import React from 'react';

import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { withRouter } from "react-router-dom";

import Lookbook from '../../molecules/lookbook';

class LookbookView extends React.Component {

  componentDidMount() {

    let initParams = {};

    initParams.genderSlug = this.props.gender.slug;

    if (this.props.match.params.collectionSlug) {
      initParams.collectionSlug = this.props.match.params.collectionSlug;
    }

    this.props.loadCollectionLookbookPhotos(initParams);
  }

  render() {
    return <Lookbook photos={this.props.collection.photos} />;
  }
}

export default withRouter(connect({
  gender: state.shop.gender,
  collection: state.shop.collection,
  loadCollectionLookbookPhotos: sequences.shop.loadCollectionLookbookPhotos
}, LookbookView));