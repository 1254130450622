import * as sequences from './sequences';
import * as providers from './providers';
import { firstCollection } from './computed';

const state = {
  state: {
    showAgeGroups: false,
    genders: [{
      "title": "Girls", "slug": "girls", "hid": "girls"
    }, {
      "title": "Boys", "slug": "boys", "hid": "boys"
    }],
    gender: {
      "title": "Girls", "slug": "girls", "hid": "girls"
    },
    ageGroups: [{
      "title": "Kids", "slug": "kids", "hid": "kids"
    }, {
      "title": "Teens", "slug": "teens", "hid": "teens"
    }],
    ageGroup: {
      "title": "Kids", "slug": "kids", "hid": "kids"
    },
    collections: null,
    collection: null,
    categories: null,
    category: null,
    products: null,
    product: null,
    firstCollection
  }, sequences, providers
};

export default state;
