import * as utilsActions from '../utils/actions';
import * as actions from './actions';

export const loadOrders = [
  utilsActions.startLoading,
  actions.getOrders,
  actions.setOrders,
  utilsActions.stopLoading,
];

export const loadOrder = [
  utilsActions.startLoading,
  actions.getOrder,
  actions.setOrder,
  utilsActions.stopLoading,
];

export const updateOrder = [
  utilsActions.startLoading,
  actions.updateOrder,
  actions.setOrder,
  utilsActions.stopLoading,
];

export const removeVariation = [
  utilsActions.startLoading,
  actions.removeVariation,
  actions.setOrder,
  utilsActions.stopLoading,
];

export const updateVariation = [
  utilsActions.startLoading,
  actions.updateVariation,
  actions.setOrder,
  utilsActions.stopLoading,
];