import utils from './modules/utils';
import users from './modules/users';
import shop from './modules/shop';
import orders from './modules/orders';
import cart from './modules/cart';

import { ApiError } from "./errors/api-error";
import { StateError } from "./errors/state-error";

import * as sequences from './sequences';

const state = {
  modules: {
    utils,
    users,
    shop,
    orders,
    cart
  },
  state: {},
  sequences,
  catch: [
    [ApiError, sequences.handleError],
    [StateError, sequences.handleError]
  ]
};

export default state;