import React from 'react';
import { withRouter } from 'react-router-dom';

import SidebarNav from "../sidebar-nav/index";
import SidebarItem from "../../atoms/sidebar-item/index";
import {data} from "../../../util/data";

class UserNav extends React.Component {

  render() {
    return (
      <div className="user-nav">
        <SidebarNav>
          <SidebarItem text={data.myAccount} style={this.props.match.path === '/user/' ? 'active' : null} onClick={e => this.props.history.push('/user/')}/>
          <SidebarItem text={data.myOrders} style={this.props.match.path === '/user/orders/' ? 'active' : null} onClick={e => this.props.history.push('/user/orders/')} />
        </SidebarNav>
      </div>
    );
  }
}

export default withRouter(UserNav);