import React from 'react';

import './index.scss';

export default class ContentPane extends React.Component {

  static defaultProps = {
    header: null,
    title: ''
  };

  render() {

    let header = this.props.header ? <div className="content-pane__header-col">{this.props.header}</div> : null;
    let title = <div className="content-pane__title">{this.props.title}</div>;

    return (
      <div className="content-pane">
        <div className="content-pane__header">
          <div className="content-pane__header-col">{title}</div>
          {header}
        </div>
        {(this.props.subtitle ? <div className="content-pane__subtitle">{this.props.subtitle}</div> : null )}
        <div className="content-pane__content">
          {this.props.children}
        </div>
      </div>
    );
  }
}