import * as sequences from './sequences';
import * as providers from './providers';

const state = {
  state: {
    isAuthenticated: false,
    user: {}
  },
  sequences,
  providers
};

export default state;
