import { http, API_HOST, API_ROOT } from '../../../util/http';

export const collectionApi = {
  getCollections() {
    return http.get(API_HOST+API_ROOT+'collections/', true);
  },
  getCollection(id) {
    return http.get(API_HOST+API_ROOT+'collections/'+id+'/', true);
  },
  getCollectionLookbookPhotos(id) {
    return http.get(API_HOST+API_ROOT+'collections/'+id+'/lookbook/', true);
  },
  getCollectionSilhouettePhotos(id) {
    return http.get(API_HOST+API_ROOT+'collections/'+id+'/silhouettes/', true);
  },
  getCategories(id, gender, ageGroup = 'kids') {
    return http.get(API_HOST+API_ROOT+'collections/'+id+'/categories/?gender='+gender+'&age_group='+ageGroup, true);
  },
  getCategory(collection, category, gender, ageGroup = 'kids') {
    return http.get(API_HOST+API_ROOT+'collections/'+collection+'/categories/'+category+'/?gender='+gender+'&age_group='+ageGroup, true);
  },
  getProducts(collection, category, gender, ageGroup = 'kids') {
    return http.get(API_HOST+API_ROOT+'collections/'+collection+'/categories/'+category+'/products/?gender='+gender+'&age_group='+ageGroup, true);
  },
  getProduct(collection, category, product, gender, ageGroup = 'kids') {
    return http.get(API_HOST+API_ROOT+'collections/'+collection+'/categories/'+category+'/products/'+product+'/?gender='+gender+'&age_group='+ageGroup, true);
  },
};