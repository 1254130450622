import React from 'react';

import { withRouter } from 'react-router-dom';

import { sequences } from 'cerebral';
import { connect } from '@cerebral/react';

import { data } from "../../../util/data";

import Logo from '../../atoms/logo/index';
import LoginForm from '../../molecules/login-form/index';
import ContentPane from '../../molecules/content-pane/index';

import './index.scss';

class Login extends React.Component {

  static defaultProps = {
    login: () => { }
  };


  constructor(props) {

    super(props);

    this.state = {
      imgUrl: ""
    };

  }


  handleLogin(data) {
    this.props.login(data);
  }

  async componentDidMount() {

    try {

      const response = await fetch(process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ROOT + `get-b2b-image/`);
      const data = await response.json();

      this.setState({ imgUrl: data.result })

    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const url = this.state.imgUrl;
    return (
      <div className="login">
        <div className="wrap">
          <header className="login__header">
            <Logo />
          </header>
          <div className="login__content">
            <div className="login__photo">
              <img src={url} alt="" />
            </div>
            <div className="login__form">
              <ContentPane title={data.login}>
                <LoginForm onSubmit={data => this.handleLogin(data)} />
              </ContentPane>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect({
  login: sequences.users.login
}, Login));

