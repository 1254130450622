import * as sequences from './sequences';
import * as providers from './providers';
import { latestOrders } from './computed';

const state = {
  state: {
    order: {},
    orders: [],
    latestOrders
  },
  sequences,
  providers
};

export default state;