import React from 'react';

import { sequences, state } from 'cerebral';
import { connect } from '@cerebral/react';
import { withRouter } from 'react-router-dom';

import { data } from "../../../util/data";
import { price, date } from '../../../util/formatting';

import ContentPane from "../content-pane/index";
import Table from "../table/index";
import TableRow from "../table-row/index";
import PopoutMenu from "../popout-menu/index";
import PopoutMenuLink from "../../atoms/popout-menu-link/index";
import Label from "../../atoms/label/index";
import SlideOutPanel from "../slide-out-panel";
import IconButton from "../../atoms/icon-button";
import Cart from "../cart";
import Confirm from "../confirm";
import Message from "../message";
import DataValue from "../data-value";

import './index.scss';

class OrderList extends React.Component {

  static defaultProps = {
    showConfirmRemoveVariation: false,
    showEditOrderPanel: false,
    loadOrders: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      showCancelOrderConfirmation: false,
      cancelOrderId: null,
      removeVariationId: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.orderId && this.props.match.params.orderId !== prevProps.match.params.orderId) {
      this.props.loadOrder({
        orderId: this.props.match.params.orderId
      });
    }
  }

  componentDidMount() {
    this.props.loadOrders();

    if (this.props.match.params.orderId) {
      this.props.loadOrder({
        orderId: this.props.match.params.orderId
      });
    }
  }

  removeVariation(variationId) {

    this.setState({
      showConfirmRemoveVariation: false,
      removeVariationId: null
    });

    this.props.removeVariation({variationId: variationId});
  }

  updateVariation(variationId, amount) {
    this.props.updateVariation({
      variationId: variationId,
      amount: amount
    });
  }

  cancelOrder(orderId) {

    this.props.updateOrder({
      orderId: orderId,
      status: 'canceled'
    });

    this.setState({
      showCancelOrderConfirmation: false,
      cancelOrderId: null
    });
  }

  showCancelConfirm(orderId) {
    this.setState({
      showCancelOrderConfirmation: true,
      cancelOrderId: orderId
    });
  }

  hideCancelConfirm() {
    this.setState({
      showCancelOrderConfirmation: false,
      cancelOrderId: null
    });
  }

  showConfirmRemoveVariation(variationId) {
    this.setState({
      showConfirmRemoveVariation: true,
      removeVariationId: variationId
    });
  }

  hideConfirmRemoveVariation() {
    this.setState({
      showConfirmRemoveVariation: false,
      removeVariationId: null
    });
  }

  downloadOrder(order) {
    window.open(process.env.REACT_APP_API_HOST+process.env.REACT_APP_APPLICATION_ROOT+'static/order/'+order.id+'-'+order.orderId+'.xls');
  }

  downloadOrderPhotos(order) {
    window.open(process.env.REACT_APP_API_HOST+process.env.REACT_APP_APPLICATION_ROOT+'static/order/'+order.id+'-'+order.orderId+'-photos.zip');
  }

  render() {

    let confirm;

    if (this.state.showCancelOrderConfirmation) {
      confirm = (
        <Confirm title={data.cancelOrderConfirmTitle} text={data.cancelOrderConfirmText} onCancel={this.hideCancelConfirm.bind(this)} onConfirm={e => this.cancelOrder(this.state.cancelOrderId)} />
      );
    }

    if (this.state.showConfirmRemoveVariation) {
      confirm = (
        <Confirm title={data.confirmRemoveFromCartTitle} text={data.confirmRemoveFromCartText} onCancel={this.hideConfirmRemoveVariation.bind(this)} onConfirm={e => this.removeVariation(this.state.removeVariationId)} />
      );
    }

    let orderList = this.props.orders.map(order => {

      let userAction;
      let cancelAction;

      if (order.status === 'awaitingApproval') {
        userAction = <IconButton icon="edit-2" onClick={e => this.props.history.push('/user/orders/edit/'+order.id+'/')} />;
        cancelAction = <PopoutMenuLink text={data.orderCancel} onClick={e => this.showCancelConfirm(order.id)} />;
      }

      if (order.status === 'completed' || order.status === 'canceled' || order.status === 'inProgress') {
        userAction = <IconButton icon="eye" onClick={e => this.props.history.push('/user/orders/edit/'+order.id+'/')} />;
      }

      if (order.inCart) {
        userAction = <IconButton icon="shopping-cart" onClick={e => this.props.history.push('/user/orders/edit/'+order.id+'/')} />;
        cancelAction = null;
      }

      return (
        <TableRow key={order.id} style={this.props.order.id === order.id ? 'highlight' : ''}>
          <span className="order-list__order-id">{order.orderId}</span>
          <span className="order-list__date">{date(order.created)}</span>
          <span className="order-list__price">{price(order.totalPrice)}</span>
          <Label status={order.status} />
          {userAction}
          <PopoutMenu position="right">
            <PopoutMenuLink text={data.downloadPhotos} onClick={e => this.downloadOrderPhotos(order)} />
            <PopoutMenuLink text={data.downloadExcelFile} onClick={e => this.downloadOrder(order)} />
            {cancelAction}
          </PopoutMenu>
        </TableRow>
      );
    });

    let cart;
    let isCartEditable = !(this.props.order.status === 'completed' || this.props.order.status === 'canceled' || this.props.order.status === 'inProgress');

    if (this.props.order.products) {
      cart = <Cart
        products={this.props.order.products}
        total={this.props.order.totalPrice}
        isEditable={isCartEditable}
        canBeEmpty={false}
        onRemoveVariation={variationId => this.showConfirmRemoveVariation(variationId)}
        onUpdateVariation={(variationId, amount) => this.updateVariation(variationId, amount)}
      />
    }

    let content;
    let message;

    if (this.props.orders.length) {
      content = (
        <Table cols={[
          {
            header: data.orderId,
            width: 150
          },
          {
            header: data.date
          },
          {
            header: data.totalPrice
          },
          {
            header: data.status
          },
          {
            span: 2,
            width: 35
          }
        ]}>
          {orderList}
        </Table>
      );
    } else {
      message = <Message text={data.noOrdersYet}/>;
    }

    return (
      <React.Fragment>
        {confirm}
        <div className="order-list">
          <ContentPane title={data.myOrders} subtitle={message}>
            {content}
          </ContentPane>
          <SlideOutPanel
            title={(isCartEditable ? data.editOrder : data.viewOrder)}
            subtitle={[
              <DataValue key={1} title="Order ID">{this.props.order.orderId}</DataValue>,
              <DataValue key={2} title="Status"><Label status={this.props.order.status} /></DataValue>
            ]}
            isOpen={this.props.showEditOrderPanel}
            onClose={e => this.props.history.push('/user/orders/')}
          >
            {cart}
          </SlideOutPanel>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(connect({
  order: state.orders.order,
  orders: state.orders.orders,
  notify: sequences.utils.notify,
  loadOrder: sequences.orders.loadOrder,
  loadOrders: sequences.orders.loadOrders,
  removeVariation: sequences.orders.removeVariation,
  updateVariation: sequences.orders.updateVariation,
  updateOrder: sequences.orders.updateOrder
}, OrderList));