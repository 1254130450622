import React from 'react';

import { withRouter } from "react-router-dom";
import { connect } from '@cerebral/react';
import { sequences } from 'cerebral';

import {data} from "../../../util/data";

import Logo from "../../atoms/logo";
import ContentPane from "../../molecules/content-pane";
import Button from "../../atoms/button";
import Message from "../../molecules/message";

import './index.scss';

class Error404 extends React.Component {

  goToHomepage() {
    this.props.resetNotFound();
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="error-404">
        <div className="wrap">
          <header className="error-404__header">
            <Logo compact />
          </header>
          <div className="error-404__content">
            <ContentPane title={data.error404} subtitle={<Message text={data.error404Text} />}>
              <Button text={data.backToHome} onClick={this.goToHomepage.bind(this)} />
            </ContentPane>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect({
  resetNotFound: sequences.utils.resetNotFound
}, Error404));