import React from 'react';
import Button from "../../atoms/button";

import './index.scss';

export default class Form extends React.Component {

  static defaultProps = {
    footer: [],
    showSubmitButton: true,
    submitButtonText: 'Submit',
    validate: () => {},
    onSubmit: () => {}
  };

  constructor(props) {

    super(props);

    this.state = {
      errorMessages: {}
    };

    this.childRefs = [];

    if (this.hasMultipleChildren()) {
      this.props.children.forEach(() => {
        this.childRefs.push(React.createRef());
      });

    } else {
      this.childRefs.push(React.createRef());
    }

    this.isValid = false;
  }

  submit() {

    let data = this.handleSubmit();

    this.clearErrorMessages(() => {

      this.props.validate(this, data);

      if (this.isValid) {
        this.props.onSubmit(data);
      }
    });
  }

  onSubmit(e) {
    e.preventDefault();
    this.submit();
  }

  clearErrorMessages(cb) {

    this.isValid = true;

    this.setState({
      errorMessages: {}
    }, cb);
  }

  setErrorMessage(name, message) {

    this.isValid = false;

    let errorMessages = this.state.errorMessages;

    errorMessages[name] = message;

    this.setState({
      errorMessages: errorMessages
    });
  }

  handleSubmit() {

    let data = {};

    if (this.hasMultipleChildren()) {

      this.props.children.forEach((child, i) => {
        this.childRefs[i].current.handleSubmit(data);
      });

      return data;
    }

    this.childRefs[0].current.handleSubmit(data);

    return data;
  }

  hasMultipleChildren() {

    return this.props.children.length;
  }

  renderErrorMessage(inputName) {

    if (this.state.errorMessages[inputName]) {
      return (
        <div className="form__error-message">
          {this.state.errorMessages[inputName]}
        </div>
      );
    }
  }

  renderChildren() {

    if (this.hasMultipleChildren()) {

      return this.props.children.map((child, i) => {
        const TagName = child.type;
        return (
          <div key={i} className="form__input">
            <TagName ref={this.childRefs[i]} {...child.props} renderErrorMessage={this.renderErrorMessage.bind(this)} />
            {this.renderErrorMessage(child.props.name)}
          </div>
        );
      });
    }

    const TagName = this.props.children.type;
    return (
      <div className="form__input">
        <TagName ref={this.childRefs[0]} {...this.props.children.props} renderErrorMessage={this.renderErrorMessage.bind(this)} />
        {this.renderErrorMessage(this.props.children.props.name)}
      </div>
    );
  }

  render() {

    let submitButton = <Button text={this.props.submitButtonText} type="submit" />;

    if (! this.props.showSubmitButton) {
      submitButton = <button style={{display: 'none'}} type="submit">{this.props.submitButtonText}</button>;
    }

    let footer = (
      <div className="form__footer">
        {submitButton}
        {this.props.footer}
      </div>
    );

    return (
      <form className="form" onSubmit={e => this.onSubmit(e)}>
        {this.renderChildren()}
        {footer}
      </form>
    );
  }
}