import React from 'react';

import { sequences, state } from 'cerebral';
import { connect } from '@cerebral/react';
import { withRouter } from 'react-router-dom';

import { data } from '../../../util/data';

import Table from '../table/index';
import TableRow from '../table-row/index';
import Label from '../../atoms/label/index';
import PopoutMenu from '../popout-menu/index';
import PopoutMenuLink from '../../atoms/popout-menu-link/index';
import Button from '../../atoms/button/index';
import Widget from '../widget/index';
import Message from '../message';
import IconButton from '../../atoms/icon-button';

import './index.scss';

class LatestOrderWidget extends React.Component {

  static defaultProps = {
    latestOrders: null,
    loadOrders: () => {},
    notify: () => {}
  };

  componentDidMount() {
    this.props.loadOrders();
  }

  downloadOrder(order) {
    window.open(process.env.REACT_APP_API_HOST+process.env.REACT_APP_APPLICATION_ROOT+'static/order/'+order.id+'-'+order.orderId+'.xls');
  }

  downloadOrderPhotos(order) {
    window.open(process.env.REACT_APP_API_HOST+process.env.REACT_APP_APPLICATION_ROOT+'static/order/'+order.id+'-'+order.orderId+'-photos.zip');
  }

  render() {

    let orders = this.props.latestOrders.map(order => {

      let userAction;
      let cancelAction;

      if (order.status === 'awaitingApproval') {
        userAction = <IconButton icon="edit-2" onClick={e => this.props.history.push('/user/orders/edit/'+order.id+'/')} />;
        cancelAction = <PopoutMenuLink text={data.orderCancel} onClick={e => this.showCancelConfirm(order.id)} />;
      }

      if (order.status === 'completed' || order.status === 'canceled' || order.status === 'inProgress') {
        userAction = <IconButton icon="eye" onClick={e => this.props.history.push('/user/orders/edit/'+order.id+'/')} />;
      }

      if (order.inCart) {
        userAction = <IconButton icon="shopping-cart" onClick={e => this.props.history.push('/user/orders/edit/'+order.id+'/')} />;
        cancelAction = null;
      }

      return (
        <TableRow key={order.id}>
          <span className="latest-order-widget__order-id">{order.orderId}</span>
          <Label status={order.status} />
          {userAction}
          <PopoutMenu position="right">
            <PopoutMenuLink text={data.downloadPhotos} onClick={e => this.downloadOrderPhotos(order)} />
            <PopoutMenuLink text={data.downloadExcelFile} onClick={e => this.downloadOrder(order)} />
            {cancelAction}
          </PopoutMenu>
        </TableRow>
      );
    });

    let footer;
    let content = <Message text={data.noOrdersYet} mode="note" />;

    if (this.props.latestOrders.length) {
      content = (
        <Table>
          {orders}
        </Table>
      );

      footer = (
        <Button icon="menu" text={data.viewAll} look="small" onClick={e => this.props.history.push('/user/orders/')} />
      );
    }

    return (
      <Widget title={data.myLatestOrders} footer={footer} shadow>
        {content}
      </Widget>
    );
  }
}

export default withRouter(connect({
  latestOrders: state.orders.latestOrders,
  loadOrders: sequences.orders.loadOrders,
  notify: sequences.utils.notify
}, LatestOrderWidget));