import React from 'react';

import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { withRouter } from "react-router-dom";

import { data } from "../../../util/data";

import ContentPane from '../../molecules/content-pane/index';
import CategoryNav from '../../molecules/category-nav/index';
import Button from "../../atoms/button/index";
import Toolbar from "../../molecules/toolbar/index";
import Confirm from "../../molecules/confirm/index";
import Loader from "../../atoms/loader/index";

class ShopView extends React.Component {

  static types = {
    lookbook: 'lookbook',
    silhouettes: 'silhouettes'
  };

  static defaultProps = {
    showExtra: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDownloadConfirmation: false
    };
  }

  componentDidMount() {
    let initParams = {};

    if (this.props.match.params.ageGroupSlug) {
      initParams.ageGroupSlug = this.props.match.params.ageGroupSlug;
    }

    if (this.props.match.params.genderSlug) {
      initParams.genderSlug = this.props.match.params.genderSlug;
    }

    if (this.props.match.params.collectionSlug) {
      initParams.collectionSlug = this.props.match.params.collectionSlug;
    }

    if (this.props.match.params.categorySlug) {
      initParams.categorySlug = this.props.match.params.categorySlug;
    }

    if (this.props.match.params.productSlug) {
      initParams.productSlug = this.props.match.params.productSlug;
    }

    this.props.initShop(initParams);
  }

  componentDidUpdate(prevProps) {

    let updateParams = {};

    if (
      this.props.match.params.ageGroupSlug &&
      this.props.match.params.ageGroupSlug !== prevProps.match.params.ageGroupSlug
    ) {
      updateParams.ageGroupSlug = this.props.match.params.ageGroupSlug;
    }

    if (
      this.props.match.params.genderSlug &&
      this.props.match.params.genderSlug !== prevProps.match.params.genderSlug
    ) {
      updateParams.genderSlug = this.props.match.params.genderSlug;
    }

    if (
      this.props.match.params.collectionSlug &&
      this.props.match.params.collectionSlug !== prevProps.match.params.collectionSlug
    ) {
      updateParams.collectionSlug = this.props.match.params.collectionSlug;
    }

    if (
      this.props.match.params.categorySlug &&
      this.props.match.params.categorySlug !==  prevProps.match.params.categorySlug
    ) {
      updateParams.categorySlug = this.props.match.params.categorySlug;
    }

    if (
      this.props.match.params.productSlug &&
      this.props.match.params.productSlug !== prevProps.match.params.productSlug
    ) {
      updateParams.productSlug = this.props.match.params.productSlug;
    }

    if (Object.keys(updateParams).length) {
      this.props.initShop(updateParams);
    }
  }

  goToShop() {
    this.props.history.push('/shop/'+this.props.ageGroup.slug+'/'+this.props.gender.slug+'/'+this.props.collection.slug+'/');
  }

  goToLookbook() {
    this.props.history.push('/shop/'+this.props.ageGroup.slug+'/'+this.props.gender.slug+'/'+this.props.collection.slug+'/lookbook/');
  }

  goToSilhouettes() {
    this.props.history.push('/shop/'+this.props.ageGroup.slug+'/'+this.props.gender.slug+'/'+this.props.collection.slug+'/silhouettes/');
  }

  showDownloadConfirmation() {

    this.setState({
      showDownloadConfirmation: true,
    });
  }

  hideDownloadConfirmation() {

    this.setState({
      showDownloadConfirmation: false,
    });
  }

  downloadCollectionPhotos(type = ShopView.types.lookbook) {

    this.setState({
      showDownloadConfirmation: false,
    });

    window.open(process.env.REACT_APP_API_HOST+process.env.REACT_APP_APPLICATION_ROOT+'static/collection/'+this.props.collection.id+'-'+this.props.collection.slug+'-'+type+'.zip');
  }

  getActiveMenu(item) {
    return this.props.showExtra === item;
  }

  render() {

    if (! this.props.collection) {
      return <Loader />;
    }

    let confirm;

    if (this.state.showDownloadConfirmation) {

      confirm = (
          <Confirm
              title={data.downloadConfirmTitle}
              text={this.props.collection.downloadPhotosDisclaimer}
              confirmButtonText="Ok, I agree!"
              onConfirm={this.downloadCollectionPhotos.bind(this, this.props.showExtra)}
              onCancel={this.hideDownloadConfirmation.bind(this)}
          />
      );
    }

    let subtitle = <CategoryNav/>;

    let header = [
      <Button key={1} active icon="shopping-bag" look="small" text={data.shop} onClick={e => this.goToShop()} />,
      <Button key={2} icon="image" look="small" text={data.lookbook} onClick={e => this.goToLookbook()} />,
      <Button key={3} icon="image" look="small" text={data.silhouettes} onClick={e => this.goToSilhouettes()} />
    ];

    if (this.props.showExtra) {

      header = [
        <Button key={1} icon="shopping-bag" look="small" text={data.shop} onClick={e => this.goToShop()} />,
        <Button key={2} active={this.getActiveMenu('lookbook')} icon="image" look="small" text={data.lookbook} onClick={e => this.goToLookbook()} />,
        <Button key={3} active={this.getActiveMenu('silhouettes')} icon="image" look="small" text={data.silhouettes} onClick={e => this.goToSilhouettes()} />
      ];
    }

    if (this.props.showExtra === ShopView.types.lookbook) {

      let downloadLineSheet;

      if (this.props.collection.downloadLineSheet) {
        downloadLineSheet = (
          <button className="toolbar__link" onClick={e => window.open(this.props.collection.downloadLineSheet)}>
            {data.downloadLineSheet}
          </button>
        );
      }

      subtitle = (
        <Toolbar>
          <button className="toolbar__link" onClick={e => window.open(process.env.REACT_APP_API_HOST+process.env.REACT_APP_APPLICATION_ROOT+'static/collection/'+this.props.collection.id+'-'+this.props.collection.slug+'-packshots.zip')}>
            {data.downloadPackshots}
          </button>
          <button className="toolbar__link" onClick={this.props.collection.downloadPhotosDisclaimer ? this.showDownloadConfirmation.bind(this) : this.downloadCollectionPhotos.bind(this, this.props.showExtra)}>
            {data.downloadPhotos}
          </button>
          {downloadLineSheet}
        </Toolbar>
      );
    }

    if (this.props.showExtra === ShopView.types.silhouettes) {

      subtitle = (
        <Toolbar>
          <button className="toolbar__link" onClick={this.props.collection.downloadPhotosDisclaimer ? this.showDownloadConfirmation.bind(this) : this.downloadCollectionPhotos.bind(this, this.props.showExtra)}>
            {data.downloadPhotos}
          </button>
        </Toolbar>
      );
    }

    return (
      <React.Fragment>
        {confirm}
        <ContentPane
          title={this.props.collection.title}
          subtitle={subtitle}
          header={header}
        >
          {this.props.children}
        </ContentPane>
      </React.Fragment>
    );
  }
}

export default withRouter(connect({
  initShop: sequences.shop.initShop,
  ageGroup: state.shop.ageGroup,
  gender: state.shop.gender,
  collection: state.shop.collection,
  category: state.shop.category,
  product: state.shop.product
}, ShopView));