import React from 'react';

import './index.scss';

export default class LoadingImage extends React.Component {

  static defaultProps = {
    src: '',
    autoload: true,
    onLoaded: () => {}
  };

  constructor(props) {

    super(props);

    this.state = {
      isLoaded: false
    };

    this.img = null;
  }

  componentDidMount() {
    if (this.props.autoload) {
      this.load();
    }
  }

  componentWillUnmount() {
    this.img.src = null;
    this.img.onload = null;
  }

  load() {

    if (! this.state.isLoaded) {

      this.img = new Image();

      this.img.onload = () => {

        this.setState({
          isLoaded: true
        });
        this.props.onLoaded();
      };

      this.img.src = this.props.src;
    }
  }

  render() {

    return (
      <div className={'loading-image' + (this.state.isLoaded ? ' loading-image--loaded' : '')}>
        <img src={this.props.src} alt="" className="loading-image__img" />
      </div>
    );
  }
}