import React from 'react';

import Icon from '../../atoms/icon/index';

import './index.scss';

export default class PopoutMenu extends React.Component {

  static defaultProps = {
    position: 'left'
  };

  constructor(props) {

    super(props);

    this.state = {
      isOpen: false
    };

    this.popoutContainer = React.createRef();

    this.handleDocumentClick = e => {
      if (this.popoutContainer.current && ! this.popoutContainer.current.contains(e.target)) {
        this.toggle();
      }
    };
  }

  toggle() {
    this.setState({
      isOpen: ! this.state.isOpen
    });
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.isOpen && ! prevState.isOpen) {
      document.addEventListener('click', this.handleDocumentClick);
    }

    if (! this.state.isOpen && prevState.isOpen) {
      document.removeEventListener('click', this.handleDocumentClick);
    }
  }

  render() {
    return (
      <div ref={this.popoutContainer} className={'popout-menu' + (this.state.isOpen ? ' popout-menu--open' : '') + ' popout-menu--position-' + this.props.position} onClick={this.toggle.bind(this)}>
        <Icon type="more-vertical" look="medium" colored />
        <div className="popout-menu__content">
          {this.props.children}
        </div>
      </div>
    );
  }
}