import React from 'react';

import './index.scss';

class TableRow extends React.Component {

  static defaultProps = {
    'style': null
  };

  render() {

    let content;

    if (this.props.children.length > 1) {

      content = this.props.children.map((child, i) => {

        return (
          <td key={i} className="table-row__data">
            {child}
          </td>
        );
      });
    } else {
      content = (
        <td className="table-row__data">
          {this.props.children}
        </td>
      );
    }

    return (
      <tr className={'table-row' + (this.props.style ? ' table-row--' + this.props.style : '')}>
        {content}
      </tr>
    );
  }
}

export default TableRow;