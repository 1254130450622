import React from 'react';

import { validateForm } from "../../../util/validation";
import {data, label} from "../../../util/data";

import {withRouter} from "react-router-dom";

import TextInput from '../../atoms/text-input/index';
import Form from "../form";
import PasswordInput from "../../atoms/password-input";

class LoginForm extends React.Component {

  static defaultProps = {
    onSubmit: () => {}
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  goToRegister(e) {
    this.props.history.push('/register/');
    e.preventDefault();
  }

  goToForgotPassword(e) {
    this.props.history.push('/forgot-password/');
    e.preventDefault();
  }


  handleSubmit(data) {
    this.props.onSubmit(data);
  }

  validateForm(form, data) {

    validateForm(form, data, {
      email: ['email', 'required'],
      password: ['required']
    });
  }

  render() {

    let loginFooter = (
      <div>
        <a href="register/" title={data.register} onClick={this.goToRegister.bind(this)}>
          {data.register}
        </a>
        <a href="forgot-password/" title={data.forgotPassword} onClick={this.goToForgotPassword.bind(this)}>
          {data.forgotPassword}
        </a>
      </div>
    );

    return (
      <Form validate={this.validateForm} onSubmit={this.handleSubmit} submitButtonText={data.login} footer={loginFooter}>
        <TextInput name="email" label={label.email} />
        <PasswordInput name="password" label={label.password} />
      </Form>
    );
  }
}

export default withRouter(LoginForm);