import React from 'react';

import { connect } from '@cerebral/react';
import { sequences, state } from 'cerebral';
import { withRouter } from 'react-router-dom';

import {data} from '../../../util/data';

import Cart from '../../molecules/cart';
import SlideOutPanel from '../../molecules/slide-out-panel';
import Button from '../../atoms/button';
import Message from '../../molecules/message';
import Confirm from '../../molecules/confirm';

import './index.scss';

class CartSlideOutPanel extends React.Component {

  static defaultProps = {
    products: [],
    updateCartVariation: () => {},
    removeVariation: () => {},
    checkoutCart: () => {}
  };

  constructor(props) {

    super(props);

    this.state = {
      showConfirmRemoveVariation: false,
      removeVariationId: null
    };
  }

  componentDidMount() {
    this.props.loadCart();
  }

  updateVariation(variationId, amount) {

    this.props.updateCartVariation({
      variationId: variationId,
      amount: amount
    });
  }

  removeVariation(variationId) {

    this.setState({
      showConfirmRemoveVariation: false,
      removeVariationId: null
    });

    this.props.removeCartVariation({
      variationId: variationId
    });
  }

  showConfirmRemoveVariation(variationId) {

    this.setState({
      showConfirmRemoveVariation: true,
      removeVariationId: variationId
    });
  }

  hideConfirmRemoveVariation() {

    this.setState({
      showConfirmRemoveVariation: false,
      removeVariationId: null
    });
  }

  checkout() {
    this.props.checkoutCart();
    this.props.history.push('/user/orders/');
  }

  render() {

    let confirm;

    if (this.state.showConfirmRemoveVariation) {
      confirm = <Confirm title={data.confirmRemoveFromCartTitle} text={data.confirmRemoveFromCartText} onConfirm={e => this.removeVariation(this.state.removeVariationId)} onCancel={e => this.hideConfirmRemoveVariation()}/>;
    }

    let cart = <Message text={data.emptyCartMessage} />;
    let cartButton = <Button text={data.continueShopping} onClick={e => this.props.toggleCart()}/>;

    if (this.props.cart && this.props.cart.products && this.props.cart.products.length) {

      cart = (
        <Cart
          products={this.props.cart.products}
          total={this.props.cart.totalPrice}
          onRemoveVariation={variationId => this.showConfirmRemoveVariation(variationId)}
          onUpdateVariation={(variationId, amount) => this.updateVariation(variationId, amount)}
        />
      );

      cartButton = <Button icon="shopping-bag" text={data.checkout} onClick={e => this.checkout()}/>;
    }

    return (
      <React.Fragment>
        {confirm}
        <SlideOutPanel isOpen={this.props.isOpen} title={data.cart} onClose={e => this.props.toggleCart()} footer={cartButton}>
          {cart}
        </SlideOutPanel>
      </React.Fragment>
    );
  }
}

export default withRouter(connect({
  cart: state.cart.cart,
  isOpen: state.cart.isOpen,
  loadCart: sequences.cart.loadCart,
  updateCartVariation: sequences.cart.updateCartVariation,
  removeCartVariation: sequences.cart.removeCartVariation,
  toggleCart: sequences.cart.toggleCart,
  checkoutCart: sequences.cart.checkoutCart
}, CartSlideOutPanel));