import React from 'react';

import './index.scss';

class Widget extends React.Component {

  static defaultProps = {
    'title': '',
    'header': [],
    'footer': [],
    'shadow': false
  };

  render() {

    return (
      <div className={'widget' + (this.props.shadow ? ' widget--shadow' : '')}>
        <div className="widget__header">
          {( this.props.title ? <div className="widget__title">{this.props.title}</div> : null )}
          {this.props.header}
        </div>
        <div className="widget__content">
          {this.props.children}
        </div>
        <div className="widget__footer">
          {this.props.footer}
        </div>
      </div>
    );
  }
}

export default Widget;