import React from 'react';

import './index.scss';

class SidebarNav extends React.Component {

  render() {

    return (
      <div className="sidebar-nav">
        {this.props.children}
      </div>
    );
  }
}

export default SidebarNav;