import React from 'react';

import './index.scss';

export default class SidebarItem extends React.Component {

  static defaultProps = {
    'text': '',
    'onClick': null,
    'style': null
  };

  render() {
    return (
      <button className={'sidebar-item' + ( this.props.style ? ' sidebar-item--' + this.props.style : '' )} onClick={this.props.onClick}>
        {this.props.text}
      </button>
    );
  }
}