import React from 'react';

import { connect } from '@cerebral/react';
import { sequences } from 'cerebral';

import {countries, customErrorMessages, data, label, notification} from '../../../util/data';
import { validateForm, hasMinLength } from "../../../util/validation";

import { userApi } from "../../../state/modules/users/providers";

import Logo from '../../atoms/logo/index';
import ContentPane from '../../molecules/content-pane/index';

import Form from "../../molecules/form";
import TextInput from "../../atoms/text-input";
import InputStack from "../../molecules/input-stack";
import FormSection from "../../molecules/form-section";
import PasswordInput from "../../atoms/password-input";
import SelectInput from "../../atoms/select-input";

import './index.scss';
import Message from "../../molecules/message";

class Register extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showSuccessMessage: false
    };
  }

  createUser(data) {

    userApi.createUser(data).then(() => {
      this.setState({
        showSuccessMessage: true
      });
    }).catch((error) => {

      if (error.message === 'duplicate_user') {

        this.props.notify({
          text: notification.duplicateUser,
          icon: 'alert-triangle',
          duration: 3000
        });
      }
    });
  }

  validateUser(form, data) {

    validateForm(form, data, {
      firstName: ['required'],
      lastName: ['required'],
      telephone: ['required'],
      email: ['required', 'email'],
      password: ['required'],
      storeName: ['required'],
      companyName: ['required'],
      addressStreet: ['required'],
      addressStreetNumber: ['required'],
      addressPostalCode: ['required'],
      addressCity: ['required'],
      addressCountry: ['required'],
      deliveryFirstName: ['required'],
      deliveryLastName: ['required'],
      deliveryAddressStreet: ['required'],
      deliveryAddressStreetNumber: ['required'],
      deliveryAddressPostalCode: ['required'],
      deliveryAddressCity: ['required'],
      deliveryAddressCountry: ['required']
    });

    if (! hasMinLength(data.password, 8)) {
      form.setErrorMessage('password', customErrorMessages.minLengthPassword);
    }

    if (data.password !== data.passwordConfirm) {
      form.setErrorMessage('passwordConfirm', customErrorMessages.passwordConfirmation);
    }
  }

  renderForm() {

    if (this.state.showSuccessMessage) {
      return;
    }

    return (
      <Form validate={this.validateUser.bind(this)} onSubmit={this.createUser.bind(this)} submitButtonText={data.createAccount}>
        <FormSection title={data.personalAccountInformation}>
          <InputStack>
            <TextInput name="firstName" label={label.firstName} showRequiredIndicator />
            <TextInput name="lastName" label={label.lastName} showRequiredIndicator />
          </InputStack>
          <TextInput name="telephone" label={label.telephone} showRequiredIndicator />
          <TextInput name="email" label={label.email} showRequiredIndicator />
          <InputStack>
            <PasswordInput name="password" label={label.password} showRequiredIndicator />
            <PasswordInput name="passwordConfirm" label={label.passwordConfirmation} showRequiredIndicator />
          </InputStack>
          <TextInput name="storeName" label={label.storeName} showRequiredIndicator />
        </FormSection>
        <InputStack>
          <FormSection title={data.invoiceData}>
            <TextInput name="companyName" label={label.companyName} showRequiredIndicator />
            <TextInput name="companyVatNumber" label={label.vat} />
            <InputStack>
              <TextInput name="addressStreet" label={label.street} showRequiredIndicator />
              <TextInput name="addressStreetNumber" label={label.number} showRequiredIndicator />
            </InputStack>
            <InputStack>
              <TextInput name="addressPostalCode" label={label.postalCode} showRequiredIndicator />
              <TextInput name="addressCity" label={label.city} showRequiredIndicator />
            </InputStack>
            <SelectInput name="addressCountry" label={label.country} options={countries} default="Belgium" showRequiredIndicator />
          </FormSection>
          <FormSection title={data.deliveryData}>
            <TextInput name="deliveryFirstName" label={label.firstName} showRequiredIndicator />
            <TextInput name="deliveryLastName" label={label.lastName} showRequiredIndicator />
            <InputStack>
              <TextInput name="deliveryAddressStreet" label={label.street} showRequiredIndicator />
              <TextInput name="deliveryAddressStreetNumber" label={label.number} showRequiredIndicator />
            </InputStack>
            <InputStack>
              <TextInput name="deliveryAddressPostalCode" label={label.postalCode} showRequiredIndicator />
              <TextInput name="deliveryAddressCity" label={label.city} showRequiredIndicator />
            </InputStack>
            <SelectInput name="deliveryAddressCountry" label={label.country} options={countries} default="Belgium" showRequiredIndicator />
          </FormSection>
        </InputStack>
      </Form>
    );
  }

  render() {

    let message = <Message text={data.registerMessage} />;

    if (this.state.showSuccessMessage) {
      message = <Message icon="check" text={data.registerSuccessMessage} mode="success" />;
    }

    return (
      <div className="register">
        <div className="wrap">
          <header className="register__header">
            <Logo compact />
          </header>
          <div className="register__content">
            <ContentPane title={(this.state.showSuccessMessage ? data.registerSuccess : data.register)} subtitle={message}>
              {this.renderForm()}
            </ContentPane>
          </div>
        </div>
      </div>
    );
  }
}

export default connect({
  notify: sequences.utils.notify,
  createUser: sequences.users.createUser
}, Register);