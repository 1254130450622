import {state} from 'cerebral';

export const startInitializing = ({store}) => {

  return store.set(state.utils.isInitializing, true);
};

export const stopInitializing = ({store}) => {

  return store.set(state.utils.isInitializing, false);
};

export const startLoading = ({store}) => {

  return store.set(state.utils.isLoading, true);
};

export const stopLoading = ({store}) => {

  return store.set(state.utils.isLoading, false);
};

export const addNotification = ({store, props, get}) => {

  let notification = props;

  notification.id = Math.random();

  if (get(state.utils.notifications).length > 2) {
    store.shift(state.utils.notifications);
  }

  return store.push(state.utils.notifications, notification);
};

export const handleNotFound = ({props, store}) => {

  if (props.error.message === 'not_found') {
    return store.set(state.utils.showError404, true);
  }
};

export const resetNotFound = ({store}) => {
    return store.set(state.utils.showError404, false);
};
