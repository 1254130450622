import React from 'react';

import './index.scss';

export default class Wysiwyg extends React.Component {

  static defaultProps = {
    html: ''
  };

  constructor(props) {
    super(props);

    this.container = React.createRef();
  }

  setHtml(html) {
    this.container.current.innerHTML = html;
  }

  componentDidMount() {
    this.setHtml(this.props.html);
  }

  componentDidUpdate(prevProps) {

    if (this.props.html !== prevProps.html) {
      this.setHtml(this.props.html);
    }
  }

  render() {

    return (
      <div className="wysiwyg" ref={this.container}>
        {this.props.children}
      </div>
    );
  }
}