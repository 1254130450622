import * as utilsActions from '../utils/actions';
import * as orderActions from '../orders/actions';
import * as actions from './actions';

export const loadCart = [
  utilsActions.startLoading,
  actions.getCart,
  actions.setCart,
  utilsActions.stopLoading,
];

export const addCartVariation = [
  utilsActions.startLoading,
  actions.createCartOrder,
  actions.setCart,
  actions.addCartOrderVariation,
  actions.setCart,
  actions.showAddedToCartNotification,
  utilsActions.stopLoading,
];

export const updateCartVariation = [
  utilsActions.startLoading,
  actions.updateCartOrderVariation,
  actions.setCart,
  utilsActions.stopLoading,
];

export const removeCartVariation = [
  utilsActions.startLoading,
  actions.removeCartVariation,
  actions.setCart,
  utilsActions.stopLoading,
];

export const checkoutCart = [
  utilsActions.startLoading,
  actions.checkoutCart,
  actions.setCart,
  actions.toggleCart,
  orderActions.getOrders,
  orderActions.setOrders,
  actions.showCheckoutNotification,
  utilsActions.stopLoading,
];

export const toggleCart = [
  utilsActions.startLoading,
  actions.toggleCart,
  utilsActions.stopLoading,
];