import React from "react";

import { connect } from "@cerebral/react";
import { sequences, state } from "cerebral";
import { withRouter } from "react-router-dom";

import { price } from "../../../util/formatting";

import ActionInput from "../action-input";
import NumberInput from "../../atoms/number-input";
import ImageSlideshow from "../image-slideshow";
import Wysiwyg from "../../atoms/wysiwyg";
import Loader from "../../../components/atoms/loader";

import "./index.scss";

class ProductView extends React.Component {
  static defaultProps = {
    product: {},
    addCartVariation: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPrice: 0,
    };

    this.variationsInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product !== this.props.product) {
      this.setState({ currentPrice: this.props.product.price });
    }
  }

  pushPhoto(photos, photo) {
    const filteredPhotos = photos.filter((p) => p.id === photo.id);

    if (filteredPhotos.length === 0) {
      photos.push(photo);
    }

    return photos;
  }

  getPhotos() {
    let photos = [];

    if (this.props.product.photo) {
      this.pushPhoto(photos, this.props.product.photo);
    }

    if (this.props.product.photos) {
      this.props.product.photos.forEach((photo) => {
        photos = this.pushPhoto(photos, photo);
      });
    }

    return photos;
  }

  addToCart(id, amount) {
    return this.props.addCartVariation({ variationId: id, amount: amount });
  }

  render() {
    if (!this.props.product) {
      return <Loader />;
    }

    let composition = "";

    if (
      this.props.product.composition &&
      this.props.product.composition.length
    ) {
      composition = this.props.product.composition
        .map((material, i) => {
          return material.percentage + "% " + material.material;
        })
        .join(", ");
    }

    let variations;

    if (this.props.product.variations && this.props.product.variations.length) {
      variations = this.props.product.variations.filter((variations) => variations.inStock > 0).map((variation, index) => {
          return (
            <div key={index} className="product-view__cart-row">
              <div className="product-view__cart-select">
                {variation.size.name +
                  " (" +
                  variation.size.tag +
                  ") - € " +
                  variation.price}
              </div>
              <div className="product-view__cart-action">
                <ActionInput
                  field={<NumberInput />}
                  buttonIcon="plus"
                  onClick={(amount) => this.addToCart(variation.id, amount)}
                />
              </div>
            </div>
          );

      });
    }

    return (
      <div className="product-view">
        <div className="product-view__cols">
          <div className="product-view__col">
            <div className="product-view__gallery">
              <ImageSlideshow images={this.getPhotos()} />
            </div>
          </div>
          <div className="product-view__col">
            <div className="product-view__content">
              <div className="product-view__header">
                <h1 className="product-view__title">
                  {this.props.product.name}
                </h1>
                <span className="product-view__price">
                  {price(this.state.currentPrice)}
                </span>
              </div>
              <div className="product-view__description">
                <Wysiwyg
                  html={
                    this.props.product.description +
                    (composition &&
                      "<p><strong>Composition:</strong> " +
                        composition +
                        "</p>")
                  }
                />
              </div>
              {variations && (
                <div className="product-view__cart">{variations}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    {
      product: state.shop.product,
      addCartVariation: sequences.cart.addCartVariation,
    },
    ProductView,
  ),
);

