import React from 'react';

import Icon from '../icon/index';

import './index.scss';

export default class Button extends React.Component {

  static defaultProps = {
    'text': '',
    'icon': null,
    'look': null,
    'active': false,
    'type': 'button',
    onClick: () => {}
  };

  render() {

    let icon;

    if (this.props.icon) {
      icon = (
        <div className="button__icon">
          <Icon type={this.props.icon} />
        </div>
      );
    }

    return (
      <button type={this.props.type} className={'button' + (this.props.look ? ' button--'+this.props.look : '') + (this.props.active ? ' button--active' : '')} onClick={this.props.onClick}>
        {icon}
        {this.props.text}
      </button>
    );
  }
}