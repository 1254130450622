import React from 'react';

import Overlay from '../../atoms/overlay';
import IconButton from "../../atoms/icon-button";

import './index.scss';

class SlideOutPanel extends React.Component {

  static defaultProps = {
    title: '',
    subtitle: '',
    isOpen: false,
    onClose: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      zIndex: 0
    };
  }

  updateZIndex(zIndex) {
    this.setState({
      zIndex: zIndex
    });
  }

  render() {

    let overlay = <Overlay isOpen={this.props.isOpen} onClick={this.props.onClose} onZIndexAssigned={zIndex => this.updateZIndex(zIndex)} />;

    return (
      <React.Fragment>
        {overlay}
        <div className={'slide-out-panel' + (this.props.isOpen ? ' slide-out-panel--open' : '')} style={{zIndex: this.state.zIndex}}>
          <div className="slide-out-panel__header">
            <div className="slide-out-panel__title">{this.props.title}</div>
            {(this.props.subtitle ? <div className="slide-out-panel__subtitle">{this.props.subtitle}</div> : null )}
            <div className="slide-out-panel__close">
              <IconButton icon="x" onClick={this.props.onClose} iconStyle="large" />
            </div>
          </div>
          <div className="slide-out-panel__content">
            {this.props.children}
          </div>
          <div className="slide-out-panel__footer">
            {this.props.footer}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SlideOutPanel;