import React from 'react';
import { icons } from 'feather-icons';

import './index.scss';

export default class Icon extends React.Component {

  static defaultProps = {
    'type': 'edit-2',
    'look': null,
    'colored': false,
    'background': false,
  };

  getIcon() {
    return icons[this.props.type].toSvg();
  }

  render() {
    return (
      <div className={'icon' + (this.props.look ? ' icon--' + this.props.look : '') + (this.props.colored ? ' icon--colored' : '') + (this.props.background ? ' icon--background' : '')}
        dangerouslySetInnerHTML={{__html: this.getIcon()}}
      >
      </div>
    );
  }
}