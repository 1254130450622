import React from "react";
import {Redirect, Route} from "react-router-dom";

import BaseLayout from "./components/organisms/base-layout/index";
import ShopView from "./components/organisms/shop-view/index";
import LookbookView from "./components/organisms/lookbook-view/index";
import SilhouetteView from "./components/organisms/silhouette-view";
import UserView from "./components/molecules/user-view";
import OrderList from "./components/molecules/order-list";
import Login from "./components/organisms/login";
import ProductList from "./components/molecules/product-list";
import ProductView from "./components/molecules/product-view";
import ShopHome from "./components/organisms/shop-home"
import Register from "./components/organisms/register";
import ForgotPassword from './components/organisms/forgot-password';
import Error404 from "./components/organisms/error-404";

export const authRequiredRoutes = [
  <Route key="register" path="/register/" exact render={() => <Redirect to="/shop/" />} />,
  <Route key="forgot-password" path="/forgot-password/" exact render={() => <Redirect to="/shop/" />} />,
  <Route key="forgot-password" path="/login/" exact render={() => <Redirect to="/shop/" />} />,
  <Route key="shop" path="/shop/" exact render={() => <BaseLayout simpleSidebar><ShopHome/></BaseLayout>} />,
  <Route key="shop" path="/shop/:ageGroupSlug/" exact render={() => <BaseLayout><ShopView><ProductList /></ShopView></BaseLayout>} />,
  <Route key="shop" path="/shop/:ageGroupSlug/:genderSlug/" exact render={() => <BaseLayout><ShopView><ProductList /></ShopView></BaseLayout>} />,
  <Route key="shop" path="/shop/:ageGroupSlug/:genderSlug/:collectionSlug/" exact render={() => <BaseLayout><ShopView><ProductList /></ShopView></BaseLayout>} />,
  <Route key="shop" path="/shop/:ageGroupSlug/:genderSlug/:collectionSlug/lookbook/" exact render={() => <BaseLayout><ShopView showExtra={'lookbook'}><LookbookView /></ShopView></BaseLayout>} />,
  <Route key="shop" path="/shop/:ageGroupSlug/:genderSlug/:collectionSlug/silhouettes/" exact render={() => <BaseLayout><ShopView showExtra={'silhouettes'}><SilhouetteView /></ShopView></BaseLayout>} />,
  <Route key="shop" path="/shop/:ageGroupSlug/:genderSlug/:collectionSlug/:categorySlug/" exact render={() => <BaseLayout><ShopView><ProductList /></ShopView></BaseLayout>} />,
  <Route key="shop" path="/shop/:ageGroupSlug/:genderSlug/:collectionSlug/:categorySlug/:productSlug/" exact render={() => <BaseLayout><ShopView><ProductView /></ShopView></BaseLayout>} />,
  <Route key="user" path="/user/" exact render={() => <BaseLayout mode="user"><UserView showEditUserPanel={false} /></BaseLayout>} />,
  <Route key="user" path="/user/edit/" exact render={() => <BaseLayout mode="user"><UserView showEditUserPanel={true} /></BaseLayout>} />,
  <Route key="user" path="/user/edit-delivery/" exact render={() => <BaseLayout mode="user"><UserView showEditUserDeliveryPanel={true} /></BaseLayout>} />,
  <Route key="user" path="/user/edit-invoice/" exact render={() => <BaseLayout mode="user"><UserView showEditUserInvoicePanel={true} /></BaseLayout>} />,
  <Route key="user" path="/user/orders/" exact render={() => <BaseLayout mode="user"><OrderList /></BaseLayout>} />,
  <Route key="user" path="/user/orders/edit/:orderId/" exact render={() => <BaseLayout mode="user"><OrderList showEditOrderPanel={true}/></BaseLayout>} />,
  <Route key="home" path="/" exact render={() => <Redirect to="/shop/" />} />,
  <Route key="error404" component={Error404} />
];

export const defaultRoutes = [
  <Route key="register" path="/register/" exact render={() => <Register />} />,
  <Route key="forgot-password" path="/forgot-password/" exact render={() => <ForgotPassword />} />,
  <Route key="login" path="/" exact render={() => <Login />} />,
  <Route key="login" component={Login} />
];