import React from 'react';

import { connect } from '@cerebral/react';
import { sequences } from 'cerebral';

import { userApi } from '../../../state/modules/users/providers';

import { data, label, notification } from '../../../util/data';
import { validateForm } from '../../../util/validation';

import Logo from '../../atoms/logo/index';
import ContentPane from '../../molecules/content-pane/index';
import Form from '../../molecules/form';
import TextInput from '../../atoms/text-input';
import Message from '../../molecules/message';

import './index.scss';

class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showSuccessMessage: false
    };
  }

  requestForgotPassword(data) {

    userApi.forgotPassword(data).then(() => {

      this.setState({
        showSuccessMessage: true
      });

    }).catch((error) => {

      if (error.message === 'non_existing_user') {

        this.props.notify({
          text: notification.nonExistingUser,
          icon: 'alert-triangle',
          duration: 3000
        });
      }
    });
  }

  validateUser(form, data) {
    validateForm(form, data, {
      email: ['required', 'email']
    });
  }

  renderForm() {

    if (this.state.showSuccessMessage) {
      return;
    }

    return (
      <Form validate={this.validateUser.bind(this)} onSubmit={this.requestForgotPassword.bind(this)} submitButtonText={data.requestNewPassword}>
        <TextInput name="email" label={label.email} showRequiredIndicator />
      </Form>
    );
  }

  render() {

    let message = <Message text={data.forgotPasswordMessage} />;

    if (this.state.showSuccessMessage) {
      message = <Message icon="check" mode="success" text={data.forgotPasswordSuccessMessage} />;
    }

    return (
      <div className="forgot-password">
        <div className="wrap">
          <header className="forgot-password__header">
            <Logo compact />
          </header>
          <div className="forgot-password__content">
            <ContentPane title={data.forgotPassword} subtitle={message}>
              {this.renderForm()}
            </ContentPane>
          </div>
        </div>
      </div>
    );
  }
}

export default connect({
  notify: sequences.utils.notify,
  forgotPassword: sequences.users.forgotPassword
}, ForgotPassword);