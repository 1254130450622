import { http, API_HOST, API_ROOT } from '../../../util/http';

export const userApi = {
  authenticate(email, password) {
    return http.request({
      url: API_HOST+API_ROOT+'authenticate/',
      headers: {
        'Authorization': 'Basic '+ btoa(email+':'+password)
      }
    });
  },
  authorize() {
    return http.get(API_HOST+API_ROOT+'authorize/', true);
  },
  getUser(id) {
    return http.get(API_HOST+API_ROOT+'users/'+id+'/', true);
  },
  createUser(data) {
    return http.post(API_HOST+API_ROOT+'users/', data);
  },
  updateUser(id, data) {
    return http.patch(API_HOST+API_ROOT+'users/'+id+'/', data, true);
  },
  forgotPassword(data) {
    return http.post(API_HOST+API_ROOT+'users/reset-password/', data, true);
  }
};