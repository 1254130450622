import * as utilsActions from '../../modules/utils/actions';
import * as actions from '../../modules/shop/actions';

export const initShop = [
  utilsActions.startLoading,
  actions.getAgeGroup,
  actions.setAgeGroup,
  actions.getGender,
  actions.setGender,
  actions.getCollections,
  actions.setCollections,
  actions.getCollection,
  actions.setCollection,
  actions.getCategories,
  actions.setCategories,
  actions.getCategory,
  actions.setCategory,
  actions.getProducts,
  actions.setProducts,
  actions.getProduct,
  actions.setProduct,
  utilsActions.stopLoading
];

export const loadCollections = [
  utilsActions.startLoading,
  actions.getCollections,
  actions.setCollections,
  utilsActions.stopLoading
];

export const loadCollectionLookbookPhotos = [
  utilsActions.startLoading,
  actions.getCollections,
  actions.setCollections,
  actions.getCollection,
  actions.setCollection,
  actions.getCollectionLookbookPhotos,
  actions.setCollectionLookbookPhotos,
  utilsActions.stopLoading
];

export const loadCollectionSilhouettePhotos = [
  utilsActions.startLoading,
  actions.getCollections,
  actions.setCollections,
  actions.getCollection,
  actions.setCollection,
  actions.getCollectionSilhouettePhotos,
  actions.setCollectionSilhouettePhotos,
  utilsActions.stopLoading
];