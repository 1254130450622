import React from 'react';

import './index.scss';
import Icon from "../../atoms/icon";

class Notification extends React.Component {

  static defaultProps = {
    'text': '',
    'icon': 'bell',
    'duration': 2000
  };

  constructor(props) {
    super(props);

    this.state = {
      isExpired: false
    };

    this.expireTimeout = null;
  }

  componentDidMount() {
    this.expireTimeout = setTimeout(() => {
      this.setState({
        isExpired: true
      });
    }, this.props.duration);
  }

  componentWillUnmount() {
    clearTimeout(this.expireTimeout);
  }

  render() {
    return (
      <div className={'notification' + (this.state.isExpired ? ' notification--expired' : '' )}>
        <div className="notification__icon">
          <Icon type={this.props.icon} />
        </div>
        <div className="notification__text">
          {this.props.text}
        </div>
      </div>
    );
  }
}

export default Notification;