import { state } from 'cerebral';

export const getOrders = ({ordersApi}) => {

  return ordersApi.getOrders().then(response => {

    let orders = response.result;
    return {orders};
  });
};

export const setOrders = ({store, props}) => {

  return store.set(state.orders.orders, props.orders);
};

export const updateOrder = ({ordersApi, store, props}) => {

  let orderId = props.orderId;

  return ordersApi.updateOrder(orderId, props).then(response => {

    let order = response.result;
    return {order};
  });
};

export const getOrder = ({ordersApi, store, props}) => {

  return ordersApi.getOrder(props.orderId).then(response => {

    let order = response.result;
    return {order};
  });
};

export const setOrder = ({store, props, get}) => {

  let orders = get(state.orders.orders);

  if (orders) {

    for (let i = 0; i < orders.length; i++) {
      if (orders[i].id === props.order.id) {
        orders[i] = props.order;
      }
    }

    store.set(state.orders.orders, orders);
  }

  return store.set(state.orders.order, props.order);
};

export const removeVariation = ({ordersApi, props, get}) => {

  let order = get(state.orders.order);

  return ordersApi.removeVariation(order.id, props.variationId).then(response => {

    let order = response.result;
    return {order};
  });
};

export const updateVariation = ({ordersApi, props, get}) => {

  let order = get(state.orders.order);

  return ordersApi.updateVariation(order.id, props.variationId, {amount: props.amount}).then(response => {

    let order = response.result;
    return {order};
  });
};