import React from 'react';

import { withRouter } from "react-router-dom";


import { sequences } from 'cerebral';
import { connect } from '@cerebral/react';

import './index.scss';

class Logo extends React.Component {

  static defaultProps = {
    compact: false
  };

  goToHomepage() {
    this.props.resetNotFound();
    this.props.history.push('/');
  }

  render() {
    return (
      <div className={'logo' + (this.props.compact ? ' logo--compact' : '')} onClick={this.goToHomepage.bind(this)}>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 162.4 29">
          <g>
            <path className="st1" fill="black" d="M129.2,5c-2.3,0-4,0.7-5.3,2.1c-1.2,1.4-1.8,3.4-1.8,6.1c0,5.3,2.5,8,7.4,8c1.6,0,3.5-0.4,5.4-1.1
            c0.2-0.1,0.3-0.3,0.3-0.5l-0.7-2.6c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3,0c-3.3,0.9-5.2,1-6.4,0c-0.4-0.4-0.7-1-0.8-1.8h8.1
            c0.2,0,0.4-0.2,0.4-0.4c0.1-0.7,0.1-1.5,0.1-2.3c0-2.3-0.6-4-1.7-5.2C132.6,5.6,131.1,5,129.2,5z M126.8,11.1
            c0.1-0.6,0.2-1.2,0.5-1.6c0.3-0.5,0.8-0.7,1.5-0.7c0.7,0,1.2,0.2,1.5,0.6c0.3,0.4,0.5,1,0.5,1.7H126.8z"/>
            <path className="st1" d="M16.8,3.8c-0.9,0-1.7,0.2-2.4,0.5c-0.6,0.2-1.2,0.6-2,1.1c-0.8-1.1-2-1.6-3.4-1.6C8.1,3.8,7.3,4,6.6,4.2
            C6.2,4.4,5.7,4.6,5.2,4.9L5.1,4.6C5,4.3,4.7,4.1,4.3,4.1H1.2c-0.4,0-0.8,0.4-0.8,0.8V19c0,0.4,0.4,0.8,0.8,0.8h3.7
            c0.4,0,0.8-0.4,0.8-0.8V9.7C6.4,9.2,7,8.9,7.5,8.9c0.2,0,0.7,0,0.7,1.8V19c0,0.4,0.4,0.8,0.8,0.8h3.7c0.4,0,0.8-0.4,0.8-0.8V9.8
            c0.7-0.6,1.4-0.9,1.8-0.9c0.2,0,0.7,0,0.7,1.8V19c0,0.4,0.4,0.8,0.8,0.8h3.7c0.4,0,0.8-0.4,0.8-0.8V9.7c0-1.8-0.4-3.3-1.2-4.3
            C19.4,4.4,18.2,3.8,16.8,3.8z"/>
            <path className="st1" d="M161.8,7.4c-0.1-0.2-0.4-0.3-0.6-0.3h-3.5c-0.4,0-0.7,0.2-0.8,0.6l-1.9,6.9L153,7.6c-0.1-0.3-0.4-0.6-0.8-0.6
            h-3.6c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.5-0.1,0.7l4.5,13.9c-0.2,0.7-0.5,1.2-1,1.7c-0.6,0.6-1.2,1-1.8,1.3
            c-0.2,0.1-0.4,0.3-0.4,0.5c-0.1,0.2-0.1,0.4,0,0.6l1.3,2.5c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.2,0,0.3-0.1c1.4-0.7,2.6-1.6,3.5-2.7
            c0.9-1.1,1.7-2.5,2.3-4.3l4.6-13.8C162,7.8,161.9,7.6,161.8,7.4z"/>
            <path className="st1" d="M100.1,0.8h-3.9c-0.5,0-0.8,0.4-0.8,0.8v20.8c0,0.5,0.4,0.8,0.8,0.8h3.9c0.5,0,0.8-0.4,0.8-0.8V13l-0.3,0.6
            c0.5,1.5-1.1,0.8-1.5,0c0.9-0.2,1.2,0,1.8-0.7V1.6C101,1.2,100.6,0.8,100.1,0.8z M100.1,19.2c-0.6,0-0.8-1,0-0.9V19.2z M100.3,16.8
            c-0.2-0.2-0.5-0.2-0.9-0.2C99.3,16,100.3,16.2,100.3,16.8z M98,7C99,7.7,98.3,8.4,98,7L98,7z M98.4,9.7c0.6,0.2,0,0.8-0.6,0.6
            C97.9,9.9,98.5,10.2,98.4,9.7z M96.7,6.6c0.1,0,0.1,0,0.2,0C97.1,7.3,96.6,7.3,96.7,6.6z M96.6,11.4C97.2,11.7,95.8,11.9,96.6,11.4
            L96.6,11.4z M96.6,9.9c0.2,0.1,0.4,0.2,0.4,0.6C96.7,10.4,96.5,10.2,96.6,9.9z M97.1,7.7C97.7,8,96.4,8.2,97.1,7.7L97.1,7.7z
            M97.9,16.9c0.5,0,0.4,0.7,0.2,0.9c-0.1-0.1-0.3-0.2-0.6-0.2C97.6,17.4,97.8,17.2,97.9,16.9z M97.5,19.5c0.3-0.3,0.5,0.2,1.1,0
            C98.8,20.3,97.3,20.3,97.5,19.5z M98,11c0.4-0.1,0.5,0.1,0.7,0.2C98.8,11.5,97.7,11.5,98,11z M99.1,7.9C99.7,7.9,99,8.6,99.1,7.9
            L99.1,7.9z M99.1,9.5c0.2-0.4,0.6-0.5,0.6-1.1C100.2,8.8,99.7,9.7,99.1,9.5z"/>
            <path className="st1" d="M74.8,2.9c-0.3-0.1-0.5-0.1-0.6-0.1c-0.1,0-0.3-0.1-0.5-0.1c-0.2,0-0.5,0-1,0c-0.6,0-1.2,0.2-2,0.5
            C70.4,3.4,70,3.7,69.6,4l-0.1-0.2C69.4,3.3,69.1,3,68.6,3h-3.4c-0.5,0-0.9,0.4-0.9,0.9v16.4c0,0.5,0.4,0.9,0.9,0.9h4.3
            c0.5,0,0.9-0.4,0.9-0.9V9.8C71.3,9,72,8.5,72.6,8.5c0.7,0,1.1,0.1,1.3,0.1c0.3,0.1,0.6,0.1,0.8-0.1c0.2-0.2,0.4-0.4,0.4-0.7
            l0.4-3.9C75.6,3.5,75.3,3.1,74.8,2.9z M67.8,11.4C67.2,12.2,67.1,10.8,67.8,11.4L67.8,11.4z M65.7,9.6c0.2,0,0.4,0,0.6,0
            c0,0.2,0,0.4,0.2,0.4c0,0.3-0.5,0.2-0.8,0.2C65.7,10,65.7,9.8,65.7,9.6z M65.4,12.1c0.8,0,0,0.8,0.4,0.8
            C65.5,13.6,64.9,12.4,65.4,12.1z M65.2,15.8C65.8,16,64.9,16.9,65.2,15.8L65.2,15.8z M65.2,14.6c0.1-0.5,1-0.1,0.8,0.4
            C65.4,15.1,65.6,14.6,65.2,14.6z M66.4,15.2c0.6,0.2,0,0.9-0.6,0.6C65.8,15.4,66.5,15.7,66.4,15.2z M66,16.6
            c0.4-0.1,0.5,0.1,0.8,0.2C66.9,17.2,65.7,17.2,66,16.6z M67.2,13.2C67.8,13.2,67,14,67.2,13.2L67.2,13.2z M67.4,19.2
            C66.7,18.7,68.1,18.3,67.4,19.2L67.4,19.2z M67.6,17.3C67.1,16.4,68.2,15.7,67.6,17.3L67.6,17.3z M67.2,15c0.2-0.4,0.6-0.6,0.6-1.2
            C68.4,14.1,67.8,15.1,67.2,15z M68,17.9C68.6,18,67.8,18.8,68,17.9L68,17.9z M68.6,16.7c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.3,0-0.4
            c0.1,0,0.3,0,0.4,0C68.6,16.4,68.6,16.6,68.6,16.7z"/>
            <path className="st1" d="M39.3,0.1c-2.4,0-4.4,0.8-5.7,2.4c-1.3,1.5-1.9,3.7-1.9,6.3c0,2.6,0.7,4.7,2,6.3c1.3,1.6,3.3,2.4,5.8,2.4
            c2.5,0,4.5-0.8,5.9-2.3c1.4-1.5,2.1-3.6,2.1-6.2c0-2.6-0.7-4.7-2.2-6.3C43.7,0.9,41.8,0.1,39.3,0.1z M32.9,11.2
            C33.4,11.2,32.7,12,32.9,11.2L32.9,11.2z M33,7.6c0.1,0,0.2,0,0.4,0C33.6,8.2,33,7.9,33,7.6z M33.2,11.7
            C33.8,11.8,33,12.5,33.2,11.7L33.2,11.7z M39.5,5c0.6,0,2.1,0,2.1,3.7c0,1.3-0.2,2.3-0.5,3c-0.2,0.3-0.5,0.7-1.5,0.7
            c-0.6,0-2.2,0-2.2-3.7S38.9,5,39.5,5z M37.7,13.1C37,12.5,38.3,12.4,37.7,13.1L37.7,13.1z M37,11.7c0.3-0.1,0.3,1-0.2,0.8
            C36.7,12.1,36.9,12,37,11.7z M36.4,8.7C37,8.8,36.2,9.5,36.4,8.7L36.4,8.7z M35.1,10.6c0.3,0,0.5,0,0.7,0c0,0.2,0,0.5,0,0.7
            c-0.3-0.2-0.6-0.6-0.7,0C34.6,11.3,35.4,11,35.1,10.6z M35.5,8.4c0,0.6-0.2,0.9-0.7,0.9C34.8,8.9,34.9,8.3,35.5,8.4z M34.4,9.9
            C34.9,9.9,34.2,10.6,34.4,9.9L34.4,9.9z M34.5,10.6l0.2,0.3h-0.5C34.3,10.9,34.2,10.6,34.5,10.6z M35,15c-0.2-0.8-0.3-1.6-0.3-1.6
            L34.3,13l0.9-0.4v-0.5l0.2-0.8l0.3,0.5l0.4,0.3c0,0-0.4,0.2-0.4,0.5s0.8,0.6,0.8,0.6l-0.4,0.5c0,0-0.7-0.6-0.8-0.3s0,0.6,0,0.6
            l0.3,0.9l0.5,0.2C36.2,15,35.3,15.8,35,15z M41.1,15c-0.5-0.1-0.5,0.2-0.3,0.5c0.1,0.2-0.5,0.5-0.5,0.5L39.7,15h-1.4v0.6l-0.8,0.3
            l-1-0.2l1.2-0.3c0-0.2,0.2-1.2,0.2-1.2s0.6,0.2,0.9,0.2s1.4-0.4,2-0.7c0.6-0.3,0.7,0.3,0.8,0.5s0.6,0.4,0.7,0.7
            C42.4,15.2,41.6,15.1,41.1,15z"/>
          </g>
        </svg>
        <div className="logo__label">PRO</div>
      </div>
    );
  }
}

export default withRouter(connect({
  resetNotFound: sequences.utils.resetNotFound
},Logo));