import React from 'react';

import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { withRouter } from 'react-router-dom';

import { data } from '../../../util/data';
import LoadingImage from '../../atoms/loading-image';
import Button from '../../atoms/button';

import './index.scss';

class GenderList extends React.Component {

  static defaultProps = {
    collection: null, firstCollection: null
  };

  static clickLink(gender, ageGroup, collection, type) {

  }

  componentDidMount() {
    this.props.loadCollections();
  }

  goTo(gender, type = '') {
    return () => {
      this.props.history.push(`/shop/kids/${gender.slug}/${this.props.firstCollection.slug}/${type}`)
    }
  }

  photoSource(gender) {
    if (gender.slug === 'girls') {
      return this.props.firstCollection.photoGirls.large;
    }

    return this.props.firstCollection.photoBoys.large;
  }

  render() {

    if (!this.props.firstCollection) {
      return null;
    }

    return (
      <div className="gender-list">
        {this.props.genders.map((gender, index) => (
          <div key={'gender-list_' + gender.slug} className="gender-list__item">
            <h2 className="gender-list__title" onClick={this.goTo(gender)}>{gender.title}</h2>
            <div className="gender-list__photo" onClick={this.goTo(gender)}>
              <LoadingImage src={this.photoSource(gender)}/>
            </div>
            <div className={'gender-list__content-box' + (index % 2 ? ' gender-list__content-box--alternate' : '')}>
              <h3 className="gender-list__subtitle">
                {this.props.firstCollection.title}
              </h3>
              <div className="gender-list__content-footer">
                <div className="gender-list__footer-item">
                  <Button icon="image" text={data.lookbook} onClick={this.goTo(gender, 'lookbook')}/>
                </div>
                <div className="gender-list__footer-item">
                  <Button icon="shopping-bag" text={data.shop} onClick={this.goTo(gender)}/>
                </div>
                <div className="gender-list__footer-item">
                  <Button icon="image" text={data.silhouettes} onClick={this.goTo(gender, 'silhouettes')}/>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>);
  }
}

export default withRouter(connect({
  genders: state.shop.genders,
  firstCollection: state.shop.firstCollection,
  loadCollections: sequences.shop.loadCollections
}, GenderList));