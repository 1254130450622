import React from 'react';

import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { withRouter } from "react-router-dom";

import GenderList from '../../molecules/gender-list/index';
import AgeGroupList from '../../molecules/age-group-list/index';

class ShopHome extends React.Component {
  render() {
    if (this.props.showAgeGroups) {
      return (
        <AgeGroupList/>
      )
    } else {
      return (
        <GenderList/>
      );
    }
  }
}

export default withRouter(connect({
  showAgeGroups: state.shop.showAgeGroups
}, ShopHome));