import React from 'react';

import { state } from 'cerebral';
import { connect } from '@cerebral/react';

import './index.scss';

class Loader extends React.Component {

  static defaultProps = {
    'isLoading': true,
    'size': ''
  };

  render() {

    return (
      <div className={'loader' + (this.props.isLoading ? ' loader--loading' : ' loader--idle')+(this.props.size ? ' loader--'+this.props.size : '')}></div>
    );
  }
}

export default connect({
  isLoading: state.utils.isLoading
}, Loader);