import React from 'react';

import { price } from '../../../util/formatting';

import NumberInput from '../../atoms/number-input';
import IconButton from '../../atoms/icon-button';

import './index.scss';

class CartEntry extends React.Component {

  static defaultProps = {
    isRemovable: true,
    isEditable: true,
    isCompact: false,
    product: {},
    onRemoveVariation: () => {},
    onUpdateVariation: () => {}
  };

  render() {

    let removeAction;
    let amountAction;

    if (this.props.isEditable) {

      amountAction = <NumberInput max={this.props.product.amount + this.props.product.variation.inStock} amount={this.props.product.amount} onChange={amount => this.props.onUpdateVariation(this.props.product.variation.id, amount)} />;

      if (this.props.isRemovable) {
        removeAction = <IconButton icon="trash-2" onClick={e => this.props.onRemoveVariation(this.props.product.variation.id)} />;
      }
    }

    return (
      <React.Fragment>

        <div key={this.props.product.id} className={this.props.isCompact ? 'cart-entry cart-entry--compact' : 'cart-entry'}>
          <div className="cart-entry__thumbnail">
            <img src={this.props.product.variation.thumbnail} alt={this.props.product.variation.name} />
          </div>
          <div className="cart-entry__content">
            <div className="cart-entry__info">
              <h5 className="cart-entry__title">{this.props.product.variation.name}</h5>
              <div className="cart-entry__details">
                <div className="cart-entry__price">QTY {this.props.product.amount} - {price(this.props.product.price)}</div>
                <div className="cart-entry__variation">
                  {this.props.product.variation.size.name} ({this.props.product.variation.size.tag})
                </div>
              </div>
            </div>
            <div className="cart-entry__actions">
              <div className="cart-entry__action">
                {amountAction}
              </div>
              <div className="cart-entry__action">
                {removeAction}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CartEntry;