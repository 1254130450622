import React from 'react';

import { connect } from '@cerebral/react';
import { state, sequences } from 'cerebral';
import { withRouter } from "react-router-dom";

import { data } from "../../../util/data";

import Message from "../message";
import ProductItem from "../product-item/index";

import './index.scss';

class ProductList extends React.Component {

  goToProduct(product) {
    this.props.history.push('/shop/'+this.props.ageGroup.slug+'/'+this.props.gender.slug+'/'+this.props.collection.slug+'/'+this.props.category.slug+'/'+product.slug+'/');
  }

  addToCart(variationId, amount) {
    return this.props.addCartVariation({ variationId: variationId, amount: amount });
  }

  render() {

    if (! this.props.products) {
      return null;
    }

    if (! this.props.products.length) {
      return (
        <Message text={data.noProducts} />
      );
    }

    let products = this.props.products.map(product => {
      return (
        <div key={product.id} className="product-list__item">
          <ProductItem
            title={product.name}
            price={product.price}
            photo={product.photo}
            variations={product.variations}
            onClick={() => this.goToProduct(product)}
            onAddToCart={(variationId, amount) => this.addToCart(variationId, amount)}
          />
        </div>
      );
    });

    return (
      <div className="product-list">
        {products}
      </div>
    );
  }
}

export default withRouter(connect({
  ageGroup: state.shop.ageGroup,
  gender: state.shop.gender,
  products: state.shop.products,
  category: state.shop.category,
  collection: state.shop.collection,
  addCartVariation: sequences.cart.addCartVariation
}, ProductList));