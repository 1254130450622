import {sequences, state} from 'cerebral';
import {notification} from "../../../util/data";

export const getCart = ({cartApi}) => {

  return cartApi.getCart().then(response => {

    let cart = response.result;
    return {cart};
  });
};

export const setCart = ({store, props}) => {

  return store.set(state.cart.cart, props.cart);
};

export const createCartOrder = ({ordersApi, props, get}) => {

  let cart = get(state.cart.cart);

  if (cart) {
    return {cart};
  }

  return ordersApi.createOrder({ inCart: true }).then(response => {

    let cart = response.result;
    return {cart};
  });
};

export const addCartOrderVariation = ({ordersApi, props, get}) => {

  return ordersApi.addVariation(props.cart.id, props.variationId, {amount: props.amount}).then(response => {

    let cart = response.result;
    return {cart};
  });
};

export const updateCartOrderVariation = ({ordersApi, props, get}) => {

  let cart = get(state.cart.cart);

  return ordersApi.updateVariation(cart.id, props.variationId, {amount: props.amount}).then(response => {

    let cart = response.result;
    return {cart};
  });
};

export const removeCartVariation = ({ordersApi, props, get}) => {

  let cart = get(state.cart.cart);

  return ordersApi.removeVariation(cart.id, props.variationId).then(response => {

    let cart = response.result;
    return {cart};
  });
};

export const checkoutCart = ({ordersApi, props, get}) => {

  let cart = get(state.cart.cart);

  return ordersApi.updateOrder(cart.id, { inCart: false }).then(response => {

    let cart = null;
    return {cart};
  });
};


export const showCheckoutNotification = ({props, get}) => {

  let notify = get(sequences.utils.notify);

  notify({
    text: notification.checkout,
    icon: 'shopping-bag',
    duration: 3000
  });
};

export const showAddedToCartNotification = ({props, get}) => {

  let notify = get(sequences.utils.notify);

  notify({
    text: notification.addedToCart,
    icon: 'check',
    duration: 3000
  });
};

export const toggleCart = ({get, store}) => {

  store.toggle(state.cart.isOpen);
};

export const handleOutOfStock = ({props, get}) => {

  if (props.error.message === 'out_of_stock') {

    let notify = get(sequences.utils.notify);

    notify({
      text: notification.outOfStock,
      icon: 'alert-triangle',
      duration: 3000
    });
  }
};