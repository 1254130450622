import { state } from 'cerebral';

export const firstCollection = (get) => {

  const collections = get(state.shop.collections);

  if (! collections) {
    return null;
  }

  return collections[0];
};

export const firstAgeGroup = (get) => {

  const ageGroups = get(state.shop.ageGroups);

  if (! ageGroups) {
    return null;
  }

  return ageGroups[0];
};

export const firstGender = (get) => {

  const genders = get(state.shop.genders);

  if (! genders) {
    return null;
  }

  return genders[0];
};