import React from 'react';

import OverlayWindow from "../../molecules/overlay-window";
import Icon from "../icon";

import './index.scss';

export default class SelectInput extends React.Component {

  static defaultProps = {
    default: '',
    label: '',
    name: '',
    options: [],
    windowTitle: '',
    showRequiredIndicator: false,
    onChange: () => {}
  };

  constructor(props) {

    super(props);

    this.state = {
      isOpen: false,
      value: this.props.default
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.default !== prevProps.default) {
      this.setState({value: this.props.default});
    }
  }

  getValue() {
    return this.state.value;
  }

  handleSubmit(data) {
    data[this.props.name] = this.getValue();
  }

  select(value) {

    this.setState({
      value: value,
      isOpen: false
    });

    this.props.onChange(value);
  }

  open() {
    this.setState({
      isOpen: true
    });
  }

  close() {
    this.setState({
      isOpen: false
    });
  }

  render() {

    let overlayWindow;

    if (this.state.isOpen) {

      let options = [];

      if (Array.isArray(this.props.options)) {

        this.props.options.forEach((option, i) => {

          let isSelected = (this.state.value === option);

          options.push(
            <div key={i} className={'select-input__option' + (isSelected ? ' select-input__option--highlight' : '')} onClick={e => this.select(option)}>
              <div className="select-input__option-icon">
                <Icon type={(isSelected ? 'check-circle' : 'circle')} />
              </div>
              <div className="select-input__option-value">
                {option}
              </div>
            </div>
          );
        });

      } else {

        for (let key in this.props.options) {

          if (this.props.options.hasOwnProperty(key)) {
            let isSelected = (this.state.value === key);

            options.push(
              <div key={key} className={'select-input__option' + (isSelected ? ' select-input__option--highlight' : '')} onClick={e => this.select(key)}>
                <div className="select-input__option-icon">
                  <Icon type={(isSelected ? 'check-circle' : 'circle')} />
                </div>
                <div className="select-input__option-value">
                  {this.props.options[key]}
                </div>
              </div>
            );
          }
        }
      }

      let optionList = (
        <div className="select-input__option-list">{options}</div>
      );

      overlayWindow = (
        <OverlayWindow title={this.props.windowTitle || this.props.label} onClose={this.close.bind(this)}>
          {optionList}
        </OverlayWindow>
      );
    }

    let activeValue = this.state.value || '-- Select your ' + this.props.label + ' --';

    if (!Array.isArray(this.props.options)) {
      activeValue = this.props.options[this.state.value];
    }

    let requiredIndicator;

    if (this.props.showRequiredIndicator) {
      requiredIndicator = <span className="select-input__required-indicator">*</span>;
    }

    let label;

    if (this.props.label) {
      label = <label className="select-input__label">{this.props.label}{requiredIndicator}</label>
    }

    return (
      <React.Fragment>
        {overlayWindow}
        <div className="select-input">
          {label}
          <div className="select-input__input" onClick={this.open.bind(this)}>
            {activeValue}
            <div className="select-input__input-arrow">
              <Icon type="chevron-down" look="extra-small" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}