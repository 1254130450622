import React from 'react';

import { price } from '../../../util/formatting';

import CartEntry from "../cart-entry";

import './index.scss';

class Cart extends React.Component {

  static defaultProps = {
    products: [],
    total: 0,
    isEditable: true,
    canBeEmpty: true,
    onRemoveVariation: () => {},
    onUpdateVariation: () => {}
  };

  render() {

    let isRemovable = true;
    let totalAmountProducts = this.props.products.length;

    if (! this.props.canBeEmpty) {
      isRemovable = totalAmountProducts > 1;
    }

    let products = this.props.products.map(product => {
      return (
        <div key={product.id} className="cart__entry">
          <CartEntry
            product={product}
            isCompact={totalAmountProducts > 5}
            isRemovable={isRemovable}
            isEditable={this.props.isEditable}
            onRemoveVariation={variationId => this.props.onRemoveVariation(variationId)}
            onUpdateVariation={(variationId, amount) => this.props.onUpdateVariation(variationId, amount)}
          />
        </div>
      )
    });

    return (
      <div className="cart">
        <div className="cart__entry-list">
          {products}
        </div>
        <div className="cart__total">
          <div className="cart__total-title">Total price</div>
          {price(this.props.total)}
        </div>
      </div>
    );

  }
}

export default Cart;