import {sequences, state} from 'cerebral';
import localStorage from '../../../util/local-storage';
import jwt from '../../../util/jwt';
import {notification} from "../../../util/data";

export const authorize = ({userApi, store}) => {
  if (localStorage.get('token')) {
    return userApi.authorize().then(response => {
      store.set(state.users.isAuthenticated, true);
      let userId = response.result.user_id;
      localStorage.set('token', response.result.token);

      const jwtPayload = jwt.parse(response.result.token);
      if (jwtPayload && jwtPayload.age_groups) {
        store.set(state.shop.showAgeGroups, jwtPayload.age_groups);
      }


      return {userId};
    }).catch(() => {
      localStorage.remove('token');
      store.set(state.users.user, {});
      store.set(state.users.isAuthenticated, false);
    });
  }
};

export const authenticate = ({userApi, store, props}) => {
  return userApi.authenticate(props.email, props.password).then(response => {
    store.set(state.users.isAuthenticated, true);
    localStorage.set('token', response.result.token);
    let userId = response.result.user_id;

    const jwtPayload = jwt.parse(response.result);
    if (jwtPayload && jwtPayload.age_groups) {
      store.set(state.shop.showAgeGroups, jwtPayload.age_groups);
    }


    return {userId};
  });
};

export const logout = ({store}) => {
  store.set(state.users.user, {});
  store.set(state.users.isAuthenticated, false);
  localStorage.remove('token');
};

export const getUser = ({userApi, props}) => {
  if (props.userId) {
    return userApi.getUser(props.userId).then(response => {
      let user = response.result;
      return {user};
    });
  }
};

export const updateUser = ({userApi, props, get}) => {
  return userApi.updateUser(get(state.users.user.id), props).then(response => {
    let user = response.result;
    return {user};
  });
};

export const createUser = ({userApi, props}) => {
  return userApi.createUser(props);
};

export const setUser = ({store, props, get}) => {

  let isAuthenticated = get(state.users.isAuthenticated);

  if (isAuthenticated) {
    return store.merge(state.users.user, props.user);
  }
};

export const forgotPassword = ({userApi, props}) => {
  return userApi.forgotPassword(props);
};

export const showUserUpdatedNotification = ({get}) => {

  let notify = get(sequences.utils.notify);

  notify({
    icon: 'check',
    text: notification.infoSaved,
    duration: 3000
  });
};

export const showLoginNotification = ({get}) => {

  let notify = get(sequences.utils.notify);
  let isAuthenticated = get(state.users.isAuthenticated);

  if (isAuthenticated) {
    notify({
      text: notification.login+', '+get(state.users.user.firstName),
      duration: 3000
    });
  }
};

export const showLogoutNotification = ({get}) => {

  let notify = get(sequences.utils.notify);
  let isAuthenticated = get(state.users.isAuthenticated);

  if (! isAuthenticated) {
    notify({
      text: notification.logout,
      duration: 3000
    });
  }
};

export const handleAuthFailed = ({props, get}) => {

  if (props.error.message === 'auth_failed') {

    let notify = get(sequences.utils.notify);

    notify({
      text: notification.authFailed,
      icon: 'alert-triangle',
      duration: 3000
    });
  }
};

export const handleUserNotActivated = ({props, get}) => {

  if (props.error.message === 'user_not_activated') {

    let notify = get(sequences.utils.notify);

    notify({
      text: notification.userNotActivated,
      icon: 'alert-triangle',
      duration: 3000
    });
  }
};

export const handleDuplicateUser = ({props, get}) => {

  if (props.error.message === 'duplicate_user') {

    let notify = get(sequences.utils.notify);

    notify({
      text: notification.duplicateUser,
      icon: 'alert-triangle',
      duration: 3000
    });
  }
};

export const handleNonExistingUser = ({props, get}) => {

  if (props.error.message === 'non_existing_user') {

    let notify = get(sequences.utils.notify);

    notify({
      text: notification.nonExistingUser,
      icon: 'alert-triangle',
      duration: 3000
    });
  }
};

export const handleExpiredToken = ({props, get, store}) => {

  if (props.error.message === 'expired_token') {

    store.set(state.users.user, {});
    store.set(state.users.isAuthenticated, false);
    store.set(state.utils.isInitializing, false);
    localStorage.remove('token');

    let notify = get(sequences.utils.notify);

    notify({
      text: notification.tokenExpired,
      icon: 'alert-triangle',
      duration: 3000
    });
  }
};