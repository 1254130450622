import React from 'react';

import './index.scss';
import Icon from "../icon";

export default class PasswordInput extends React.Component {

  static defaultProps = {
    default: '',
    label: '',
    name: '',
    showRequiredIndicator: false,
    onChange: () => {}
  };

  constructor(props) {

    super(props);

    this.state = {
      id: 'input-'+this.props.name+'-'+Math.random().toString(36).substring(7),
      value: this.props.default,
      type: 'password'
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.default !== prevProps.default) {
      this.setState({value: this.props.default});
    }
  }

  showPassword() {
    this.setState({
      type: 'text'
    });
  }

  maskPassword() {
    this.setState({
      type: 'password'
    });
  }

  switchMode() {
    if (this.state.type === 'password') {
      this.showPassword();
    } else {
      this.maskPassword();
    }
  }

  getValue() {
    return this.state.value;
  }

  handleChange(e) {
    this.setState({value: e.target.value});
    this.props.onChange(this.state.value);
  }

  handleSubmit(data) {
    data[this.props.name] = this.getValue();
  }

  render() {

    let requiredIndicator;

    if (this.props.showRequiredIndicator) {
      requiredIndicator = <span className="password-input__required-indicator">*</span>;
    }

    return (
      <div className="password-input">
        <label htmlFor={this.state.id} className="password-input__label">
          {this.props.label}
          {requiredIndicator}
        </label>
        <div className="password-input__input-wrap">
          <input id={this.state.id} name={this.props.name} className="password-input__input" type={this.state.type} defaultValue={this.props.default} onChange={this.handleChange} />
          <div className={'password-input__revealer' + (this.state.type === 'password' ? '' : ' password-input__revealer--unmasked')} onClick={this.switchMode.bind(this)}>
            <Icon type={this.state.type === 'password' ? 'eye' : 'eye-off'} />
          </div>
        </div>
      </div>
    );
  }
}