import React from 'react';

import { connect } from '@cerebral/react';
import { state } from 'cerebral';
import { withRouter } from 'react-router-dom';

import SidebarNav from '../sidebar-nav/index';
import SidebarItem from '../../atoms/sidebar-item/index';

class SimpleCollectionNav extends React.Component {

  goToCollection(collection) {
    this.props.history.push('/shop/'+this.props.ageGroup.slug+'/'+this.props.gender.slug+'/'+collection.slug+'/');
  }

  render() {

    if (! this.props.collections) {
      return null;
    }

    let collections = this.props.collections.map(collection => {
      return (
        <SidebarItem key={collection.id} text={collection.title} onClick={() => this.goToCollection(collection)} />
      );
    });

    return (
      <div className="collection-nav">
        <SidebarNav>
          {collections}
        </SidebarNav>
      </div>
    );
  }
}

export default withRouter(connect({
  ageGroup: state.shop.ageGroup,
  gender: state.shop.gender,
  collections: state.shop.collections,
  collection: state.shop.collection
}, SimpleCollectionNav));