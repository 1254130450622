import React from 'react';

import './index.scss';
import IconButton from "../icon-button";

class NumberInput extends React.Component {

  static defaultProps = {
    amount: 1,
    min: 1,
    max: null,
    onChange: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      value: 1
    };

    this.input = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.amount !== prevProps.amount) {
      this.setState({value: this.props.amount});
    }
  }

  componentDidMount() {
    this.setState({value: this.props.amount});
  }

  increment() {
    let amount = (this.props.max ? Math.min(this.props.max, this.state.value + 1) : this.state.value + 1);
    this.onChange(amount);
  }

  decrement() {
    let amount = Math.max(this.props.min, this.state.value - 1);
    this.onChange(amount);
  }

  handleChange(e) {

    let value = parseInt(e.target.value) || this.props.min;
    let clampMax = (this.props.max ? Math.min(this.props.max, value) : value);
    let clampMin = Math.max(this.props.min, clampMax);

    this.onChange(clampMin);
  }

  handleKeyDown(e) {

    if (e.keyCode === 38) {
      this.increment();
    }

    if (e.keyCode === 40) {
      this.decrement();
    }
  }

  onChange(amount) {
    this.setState(() => { return {value: amount}});
    this.props.onChange(amount);
  }

  getValue() {
    return this.state.value;
  }

  render() {

    return (
      <div className="number-input">
        <input ref={this.input} className="number-input__field" value={this.state.value} type="text"
               onChange={e => this.handleChange(e)}
               onKeyDown={e => this.handleKeyDown(e)}
               onKeyUp={e => this.handleChange(e)}
        />
        <div className="number-input__actions">
          <div className="number-input__button">
            <IconButton icon="chevron-up" iconStyle="extra-small" onClick={e => this.increment()} />
          </div>
          <div className="number-input__button">
            <IconButton icon="chevron-down" iconStyle="extra-small" onClick={e => this.decrement()} />
          </div>
        </div>
      </div>
    );
  }
}

export default NumberInput;