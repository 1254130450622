import React from 'react';

import OverlayWindow from "../overlay-window";
import Button from "../../atoms/button";

import './index.scss';

class Confirm extends React.Component {

  static defaultProps = {
    title: 'Confirm',
    text: '',
    confirmButtonText: 'Ok',
    cancelButtonText: 'Cancel',
    onConfirm: () => {},
    onCancel: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: true
    };
  }
  
  close() {
    this.setState({
      isOpen: false
    });
  }

  confirm() {
    this.close();
    this.props.onConfirm();
  }

  cancel() {
    this.close();
    this.props.onCancel();
  }

  render() {

    if (! this.state.isOpen) {
      return null;
    }

    return (
      <OverlayWindow title={this.props.title}>
        <div className="confirm">
          <div className="confirm__text">
            {this.props.text}
          </div>
          <div className="confirm__footer">
            <div className="confirm__button">
              <Button text={this.props.confirmButtonText} onClick={this.confirm.bind(this)}/>
            </div>
            <div className="confirm__button">
              <Button text={this.props.cancelButtonText} onClick={this.cancel.bind(this)}/>
            </div>
          </div>
        </div>
      </OverlayWindow>
    );
  }
}

export default Confirm;