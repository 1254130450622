import React from 'react';

import { withRouter } from 'react-router-dom';

import Logo from "../../atoms/logo/index";

import './index.scss';

class Sidebar extends React.Component {

  render() {
    return (
      <div className="sidebar">
        <div className="sidebar__logo" onClick={e => this.props.history.push('/')}>
          <Logo />
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(Sidebar);