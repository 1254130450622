import React from 'react';

import './index.scss';

let currentIndex = 100;

export default class Overlay extends React.Component {

  static defaultProps = {
    onClick: () => {},
    onZIndexAssigned: () => {},
    isOpen: true
  };

  constructor(props) {
    super(props);

    this.state = {
      zIndex: 0
    };

    this.overlay = React.createRef();
  }

  componentDidMount() {

    currentIndex++;

    this.setState({
      zIndex: currentIndex
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.zIndex !== prevState.zIndex) {
      this.props.onZIndexAssigned(this.state.zIndex);
    }
  }

  onClick(e) {

    if (e.target === this.overlay.current) {
      this.props.onClick();
    }
  }

  render() {

    if (! this.props.isOpen) {
      return null;
    }

    return (
      <div className="overlay" onClick={this.onClick.bind(this)} style={{zIndex: this.state.zIndex}} ref={this.overlay}>
        {this.props.children}
      </div>
    );
  }
}