import * as utilsActions from '../utils/actions';
import * as actions from './actions';

export const login = [
  utilsActions.startLoading,
  actions.authenticate,
  actions.getUser,
  actions.setUser,
  actions.showLoginNotification,
  utilsActions.stopLoading
];

export const logout = [
  utilsActions.startLoading,
  actions.logout,
  actions.showLogoutNotification,
  utilsActions.stopLoading
];

export const updateUser = [
  utilsActions.startLoading,
  actions.updateUser,
  actions.setUser,
  actions.showUserUpdatedNotification,
  utilsActions.stopLoading
];

export const createUser = [
  utilsActions.startLoading,
  actions.createUser,
  utilsActions.stopLoading
];

export const forgotPassword = [
  utilsActions.startLoading,
  actions.forgotPassword,
  utilsActions.stopLoading
];