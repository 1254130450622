import React from 'react';

import Overlay from "../../atoms/overlay";

import './index.scss';

class OverlayWindow extends React.Component {

  static defaultProps = {
    title: '',
    onClose: () => {}
  };

  render() {
    return (
      <Overlay onClick={e => this.props.onClose()}>
        <div className="overlay-window">
          <div className="overlay-window__header">
            {this.props.title}
          </div>
          <div className="overlay-window__content">
            {this.props.children}
          </div>
        </div>
      </Overlay>
    );
  }
}

export default OverlayWindow;