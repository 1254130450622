import * as sequences from './sequences';

const state = {
  state: {
    showError404: false,
    isLoading: false,
    isInitializing: true,
    notifications: []
  },
  sequences
};

export default state;
