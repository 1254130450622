import React from 'react';
import {withRouter} from "react-router-dom";

import LoadingImage from "../../atoms/loading-image";

import './index.scss';
import Message from "../message";
import {data} from "../../../util/data";

class Silhouette extends React.Component {

  static defaultProps = {
    photos: null
  };

  render() {

    if (! this.props.photos) {
      return null;
    }

    if (! this.props.photos.length) {
      return (
        <Message text={data.noSilhouttePhotos} mode="note" />
      );
    }

    let photos = this.props.photos.map(photo => {

      let renderedPhoto = (
        <div className="silhouette__img">
          <LoadingImage src={photo.photo.extraLarge} />
        </div>
      );

      let annotationProducts;

      if (photo.products.length) {
        annotationProducts = photo.products.map(product => {
          return (
            <button key={product.id} className='silhouette__annotation-link' onClick={e => this.props.history.push('/shop/'+product.path)}>{product.name}</button>
          );
        });
      }

      return (
        <div className="silhouette__photo" key={photo.id}>
          {renderedPhoto}
          {annotationProducts &&
            <div className="silhouette__annotation">
              {annotationProducts}
            </div>
          }
        </div>
      );

    });

    return (
      <div className="silhouette">
        {photos}
      </div>
    );
  }
}

export default withRouter(Silhouette);