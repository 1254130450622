import { state } from 'cerebral';
import { StateError } from "../../errors/state-error";

export const setShowAgeGroups = ({ store, props }) => {
  if (props.showAgeGroups) {
    store.set(state.shop.showAgeGroups, props.showAgeGroups);
  }
};
export const getGender = ({ get, props }) => {

  if (props.genderSlug) {
    let genders = get(state.shop.genders);

    let gender = genders.find((gender) => gender.slug === props.genderSlug);

    return { gender };
  }
};

export const setGender = ({ store, props }) => {

  if (props.gender) {
    return store.set(state.shop.gender, props.gender);
  }
};
export const getAgeGroup = ({ get, props }) => {

  if (props.ageGroupSlug) {

    let ageGroups = get(state.shop.ageGroups);

    let ageGroup = ageGroups.find((ageGroup) => ageGroup.slug === props.ageGroupSlug);

    return { ageGroup };
  }
};

export const setAgeGroup = ({ store, props }) => {

  if (props.ageGroup) {
    return store.set(state.shop.ageGroup, props.ageGroup);
  }
};

export const getCollections = ({ get, collectionApi }) => {

  let collections = get(state.shop.collections);

  if (collections) {
    return { collections };
  }

  return collectionApi.getCollections().then(response => {

    let collections = response.result;
    return { collections };
  });
};

export const setCollections = ({ store, props }) => {

  return store.set(state.shop.collections, props.collections);
};

export const getCollection = ({ collectionApi, get, props }) => {

  if (props.collectionId) {

    return collectionApi.getCollection(props.collectionId).then(response => {
      let collection = response.result;
      return { collection };
    });
  }

  let collections = get(state.shop.collections);

  if (props.collectionSlug) {

    let collection = collections.find((collection) => collection.slug === props.collectionSlug);

    if (!collection) {
      throw new StateError('not_found');
    }

    return { collection };

  } else {

    let collection = get(state.shop.collection);

    if (collection) {
      return { collection };
    }
  }

  let collection = collections[0];
  return { collection };
};

export const setCollection = ({ store, props }) => {

  return store.set(state.shop.collection, props.collection);
};

export const getCollectionLookbookPhotos = ({ collectionApi, get }) => {

  let collection = get(state.shop.collection);

  return collectionApi.getCollectionLookbookPhotos(collection.id).then(response => {

    let collectionLookbookPhotos = response.result;
    return { collectionLookbookPhotos: collectionLookbookPhotos };
  });
};

export const setCollectionLookbookPhotos = ({ store, props }) => {

  return store.set(state.shop.collection.photos, props.collectionLookbookPhotos);
};

export const getCollectionSilhouettePhotos = ({ collectionApi, get }) => {

  let collection = get(state.shop.collection);

  return collectionApi.getCollectionSilhouettePhotos(collection.id).then(response => {

    let collectionSilhouettePhotos = response.result;
    return { collectionSilhouettePhotos: collectionSilhouettePhotos };
  });
};

export const setCollectionSilhouettePhotos = ({ store, props }) => {

  return store.set(state.shop.collection.photos, props.collectionSilhouettePhotos);
};

export const getCategories = ({ collectionApi, props, get }) => {
  let categories = get(state.shop.categories);

  if (!categories || props.genderSlug || props.ageGroupSlug) {

    let collection = get(state.shop.collection);
    let gender = !props.genderSlug ? get(state.shop.gender) : {slug: props.genderSlug};
    let ageGroup = !props.ageGroupSlug ? get(state.shop.ageGroup) : {slug: props.ageGroupSlug};

    return collectionApi.getCategories(collection.id, gender.slug, ageGroup.slug).then(
      response => {
        let categories = response.result;
        return { categories };
      });
  }
};

export const setCategories = ({ store, props }) => {

  if (props.categories) {
    return store.set(state.shop.categories, props.categories);
  }
};

export const getCategory = ({ collectionApi, get, props }) => {
  if (props.categoryId) {

    let collection = get(state.shop.collection);
    let gender = get(state.shop.gender);
    let ageGroup = get(state.shop.ageGroup);

    return collectionApi.getCategory(collection.id, props.categoryId, gender.slug, ageGroup.slug)
      .then(response => {

        let category = response.result;
        return { category };
      });
  }

  let categories = get(state.shop.categories);

  if (props.categorySlug) {

    let category = categories.find((category) => category.slug === props.categorySlug);

    if (!category) {
      throw new StateError('not_found');
    }

    return { category };

  } else {

    let storedCategory = get(state.shop.category);
    if (storedCategory) {
      let category = categories.find((category) => category.slug === storedCategory.slug);

      if (category) {
        return { category };
      }
    }
  }

  let category = categories[0];
  return { category };
};

export const setCategory = ({ store, props }) => {

  return store.set(state.shop.category, props.category);
};

export const getProducts = ({ collectionApi, props, get }) => {
  let collection = get(state.shop.collection);
  let category = get(state.shop.category);
  if (!category) {
    category = {id: 0};
  }
  let gender = get(state.shop.gender);
  let ageGroup = get(state.shop.ageGroup);

  return collectionApi.getProducts(collection.id, category.id, gender.slug, ageGroup.slug).then(response => {

    let products = response.result;
    return { products };
  });
};

export const setProducts = ({ store, props }) => {

  return store.set(state.shop.products, props.products);
};

export const getProduct = ({ collectionApi, get, props }) => {

  if (props.productId) {

    let gender = get(state.shop.gender);
    let ageGroup = get(state.shop.ageGroup);

    return collectionApi.getProduct(props.collection.id,
      props.category.id,
      props.productId,
      gender.slug, ageGroup.slug).then(response => {

      let product = response.result;
      return { product };
    });
  }

  let products = get(state.shop.products);

  if (props.productSlug) {

    let product = products.find(product => product.slug === props.productSlug);

    if (!product) {
      throw new StateError('not_found');
    }

    return { product };
  }

  let product = {};

  return { product };
};

export const setProduct = ({ store, props }) => {

  return store.set(state.shop.product, props.product);
};