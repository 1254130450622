import React from 'react';

import './index.scss';

export default class PopoutMenuLink extends React.Component {

  static defaultProps = {
    'text': '',
    'onClick': null
  };

  render() {
    return (
      <button className="popout-menu-link" onClick={this.props.onClick}>
        {this.props.text}
      </button>
    );
  }
}