import React from 'react';
import ReactDOM from 'react-dom/client'
import './index.scss';
import App from './app';
import * as serviceWorker from './serviceWorker';

import Cerebral from 'cerebral';
import { Container } from '@cerebral/react';

import store from './state/index';
import DevTools from 'cerebral/devtools';

const devtools =
  process.env.NODE_ENV !== 'production' ?
    DevTools({host:'localhost:8585'}) :
    undefined;

let cerebral = Cerebral(store, {
    devtools
  });

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render( (
  <Container app={cerebral}>
    <App />
  </Container>
));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();