import * as userActions from './modules/users/actions';
import * as utilsActions from './modules/utils/actions';
import * as cartActions from './modules/cart/actions';

export const setup = [
  utilsActions.startInitializing,
  utilsActions.startLoading,
  userActions.authorize,
  userActions.getUser,
  userActions.setUser,
  utilsActions.stopLoading,
  utilsActions.stopInitializing
];

export const handleError = [
  userActions.handleAuthFailed,
  userActions.handleUserNotActivated,
  userActions.handleDuplicateUser,
  userActions.handleNonExistingUser,
  cartActions.handleOutOfStock,
  userActions.handleExpiredToken,
  utilsActions.handleNotFound,
  utilsActions.stopLoading
];