import React from 'react';

import { status } from "../../../util/data";

import './index.scss';

class Label extends React.Component {

  static defaultProps = {
    status: ''
  };

  render() {

    let style = '';

    if (this.props.status === 'completed') {
      style = 'success';
    } else if (this.props.status === 'canceled') {
      style = 'disabled';
    }

    return (
      <span className={'label' + (style ? ' label--' + style : '')}>
        {status[this.props.status] ? status[this.props.status] : 'Unknown'}
      </span>
    );
  }
}

export default Label;