import React from 'react';

import { state, sequences } from 'cerebral';
import { connect } from '@cerebral/react';

import { price } from "../../../util/formatting";
import { data } from "../../../util/data";

import Icon from "../../atoms/icon";

import './index.scss';

class CartWidget extends React.Component {

  static defaultProps = {
    toggleCart: () => {}
  };

  toggleCart() {
    this.props.toggleCart();
  }

  render() {

    let totalPrice = data.emptyCart;

    if (this.props.cart && this.props.cart.products && this.props.cart.products.length > 0) {
      totalPrice = price(this.props.cart.totalPrice);
    }

    return (
      <div className="cart-widget" onClick={this.toggleCart.bind(this)}>
        <Icon type="shopping-cart" look={'highlight'} />
        <div className="cart-widget__content">
          {totalPrice}
        </div>
      </div>
    );
  }
}

export default connect({
  cart: state.cart.cart,
  toggleCart: sequences.cart.toggleCart
}, CartWidget);