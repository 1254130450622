import localStorage from './local-storage';
import {ApiError} from "../state/errors/api-error";

export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_ROOT = process.env.REACT_APP_API_ROOT;

export const http = (() => {

  function evaluateResponse (response) {

    let json = response.json();

    return json.then(response => {

      if (response.success) {
        return json;
      }

      throw new ApiError(response.error_code, response.data);
    });
  }

  return {

    request(config = {}, authRequired = false) {
      if (authRequired) {
        config.headers = {
          'Authorization': 'Bearer '+localStorage.get('token')
        };
      }

      return fetch(config.url, config).then(evaluateResponse);
    },

    get(url, authRequired = false) {
      return this.request({
        url: url
      }, authRequired);
    },

    post(url, data, authRequired = false) {

      let formData = new FormData();

      for (let prop in data) {
        if (data.hasOwnProperty(prop)) {
          formData.append(prop, data[prop]);
        }
      }

      return this.request({
        url: url,
        method: 'POST',
        body: formData
      }, authRequired);
    },

    put(url, data, authRequired = false) {
      return this.request({
        url: url,
        method: 'PUT',
        body: JSON.stringify(data)
      }, authRequired);
    },

    patch(url, data, authRequired = false) {
      return this.request({
        url: url,
        method: 'PATCH',
        body: JSON.stringify(data)
      }, authRequired);
    },

    delete(url, authRequired = false) {
      return this.request({
        url: url,
        method: 'DELETE',
      }, authRequired);
    }
  }
})();