import React from 'react';

import { state, sequences } from 'cerebral';
import { connect } from '@cerebral/react';
import { withRouter, Link } from "react-router-dom";

import { data } from "../../../util/data";

import Icon from "../../atoms/icon";
import PopoutMenu from "../popout-menu";
import PopoutMenuLink from "../../atoms/popout-menu-link";
import Confirm from "../confirm";

import './index.scss';

class UserWidget extends React.Component {

  static defaultProps = {
    user: {},
    logout: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      showLogoutConfirm: false
    };
  }

  handleLogout() {
    this.props.logout();
    this.props.history.push('/');
  }

  toggleLogoutConfirm() {
    this.setState({
      showLogoutConfirm: ! this.state.showLogoutConfirm
    });
  }

  render() {

    let logoutConfirm;

    if (this.state.showLogoutConfirm) {
      logoutConfirm =  <Confirm onConfirm={this.handleLogout.bind(this)} onCancel={this.toggleLogoutConfirm.bind(this)} title={data.logoutConfirmTitle} text={data.logoutConfirmText} />;
    }

    return (
      <React.Fragment>
        {logoutConfirm}
        <div className="user-widget">
          <Icon type="user" look="highlight" />
          <div className="user-widget__content">
            <Link to="/user/">{this.props.user.firstName} {this.props.user.lastName}</Link>
          </div>
          <PopoutMenu position="right">
            <PopoutMenuLink text={data.myAccount} onClick={e => this.props.history.push('/user/')} />
            <PopoutMenuLink text={data.myOrders} onClick={e => this.props.history.push('/user/orders/')} />
            <PopoutMenuLink text={data.logout} onClick={this.toggleLogoutConfirm.bind(this)} />
          </PopoutMenu>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(connect({
  user: state.users.user,
  logout: sequences.users.logout
}, UserWidget));