import * as sequences from './sequences';
import * as providers from './providers';

const state = {
  state: {
    cart: {},
    isOpen: false
  },
  sequences,
  providers
};

export default state;