import { http, API_HOST, API_ROOT } from '../../../util/http';

export const ordersApi = {
  getOrders() {
    return http.get(API_HOST+API_ROOT+'orders/', true);
  },
  createOrder(data) {
    return http.post(API_HOST+API_ROOT+'orders/', data, true);
  },
  updateOrder(id, data) {
    return http.patch(API_HOST+API_ROOT+'orders/'+id+'/', data, true);
  },
  getOrder(id) {
    return http.get(API_HOST+API_ROOT+'orders/'+id+'/', true);
  },
  addVariation(orderId, variationId, data) {
    return http.post(API_HOST+API_ROOT+'orders/'+orderId+'/variations/'+variationId+'/', data, true);
  },
  updateVariation(orderId, variationId, data) {
    return http.patch(API_HOST+API_ROOT+'orders/'+orderId+'/variations/'+variationId+'/', data, true);
  },
  removeVariation(orderId, variationId) {
    return http.delete(API_HOST+API_ROOT+'orders/'+orderId+'/variations/'+variationId+'/', true);
  }
};