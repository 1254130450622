import React from 'react';

import Icon from "../../atoms/icon";

import './index.scss';

class Message extends React.Component {

  static defaultProps = {
    icon: 'info',
    text: '',
    mode: ''
  };

  render() {

    return (
      <div className={'message' + (this.props.mode ? ' message--'+this.props.mode : '')}>
        <div className="message__icon">
          <Icon type={this.props.icon} look="medium" />
        </div>
        <div className="message__text">
          {this.props.text}
        </div>
      </div>
    );
  }
}

export default Message;